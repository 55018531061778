import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
	/**
	 * 탭정보
	 */
	@Input() public tabs: any[] = [];
	constructor() {
	}

	ngOnInit(): void {
	}

	onTabChange(tab: any) {

	}
}
