

/**
 * 전역 확장 메서드
 */
interface Date {
	getComputedDate(): string;
	getFormatDateShort(): string;
	getFormatDateMiddle(): string;
	isSameDate(dateValue: Date): boolean;
	format(format: string): string;
	addDay(daysForAdd: number) : Date;
}

Date.prototype.format = function(format: string) {


	const weekKorName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
	const weekKorShortName = ['일', '월', '화', '수', '목', '금', '토'];
	const weekEngName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const weekEngShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	const d = this;
	return format.replace(/(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi,($1:any) => {
		switch ($1) {
			// 년 (4자리)
			case 'yyyy': return d.getFullYear();
			// 년 (2자리)
			case 'yy': return paddNumber((d.getFullYear() % 1000),2);
			// 월 (2자리)
			case 'MM': return paddNumber((d.getMonth() + 1) , 2);
			// 일 (2자리)
			case 'dd': return paddNumber(d.getDate(),2);
			// 요일 (짧은 한글)
			case 'KS': return weekKorShortName[d.getDay()];
			// 요일 (긴 한글)
			case 'KL': return weekKorName[d.getDay()];
			// 요일 (짧은 영어)
			case 'ES': return weekEngShortName[d.getDay()];
			// 요일 (긴 영어)
			case 'EL': return weekEngName[d.getDay()];
			// 시간 (24시간 기준, 2자리)
			case 'HH': return paddNumber(d.getHours(),2);
			// 시간 (12시간 기준, 2자리)
			case 'hh':
				const h = d.getHours() % 12;
				return paddNumber((h ? h : 12),2);
			// 분 (2자리)
			case 'mm': return paddNumber(d.getMinutes(),2);
			// 초 (2자리)
			case 'ss': return paddNumber(d.getSeconds(), 2);
			// 오전/오후 구분
			case 'a/p': return d.getHours() < 12 ? '오전' : '오후';
			default: return $1;
		}
	});

	function paddNumber(val:number , len: number) {
		return paddString(val.toString(),len);
	}

	function paddString(val:string , len: number) {
		return string('0',len - val.length) + val;
	}

	function string(val:string, len: number) {
		let result = '';
		let i = 0;
		while (i++ < len)
			result += val;

		return result;
	}
};

/**
 * 오전 hh:mm 형식으로 리턴한다
 */
Date.prototype.getFormatDateMiddle = function() {
	let result: string = '';
	const timeValue = this;
	result += `${timeValue.getMonth().toString().padStart(2, '0')} ${timeValue.getDay().toString().padStart(2, '0')}`;

	return result;
};

/**
 * 오전 hh:mm 형식으로 리턴한다
 */
Date.prototype.getFormatDateShort = function() {
	let result: string = '';
	const timeValue = this;
	// 오전 or 오후 파싱
	result += timeValue.getHours() > 12 ? '오후 ' : '오전 ';
	result += timeValue.format('hh:mm');

	return result;
};


/**
 * 동일한 날짜인지 비교한다
 * @param dateValue 데이트 값
 */
Date.prototype.isSameDate = function(dateValue) : boolean {
	return(
		this.getFullYear() === dateValue.getFullYear() &&
		this.getMonth() === dateValue.getMonth() &&
		this.getDate() === dateValue.getDate()
	);
};

/**
 * 지정한 날짜만큼 더하거나 뺀다
 * @param daysForAdd 빼거나 더할 숫자값
 */
Date.prototype.addDay = function(daysForAdd: number) : Date {
	this.setDate(this.getDate() + daysForAdd);
	return this;
};


/**
 * ..시간전 방금전 형식으로 리턴한다
 */
Date.prototype.getComputedDate = function() {
	const timeValue = this;
	return timeValue.format('yyyy-MM-dd HH:mm:ss');
};
