// 전역 릴리즈 개발 환경 변수 설정
export const environment = {
	production: true,
	apiUrl: '/api/v1' ,
	protocol: 'https' ,
	domain: 'https://autochek.co.kr' ,
	host: 'autochek.co.kr' ,
	// 공유 가능한 메타 카테고리 데이터
	shareCategoryMetas : {
		glucose:'UL_SHARE_HEALTH_DATA_NAME_GLUCOSE'		,
		blood:'UL_SHARE_HEALTH_DATA_NAME_BLOOD'		,
		scale:'UL_SHARE_HEALTH_DATA_NAME_SCALE'		,
		activity:'UL_SHARE_HEALTH_DATA_NAME_ACTIVITY'	,
		therometer:'UL_SHARE_HEALTH_DATA_NAME_THEROMETER' 	,
		food:'UL_SHARE_HEALTH_DATA_NAME_FOOD'
	}
};
