import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
	name: 'dynamicPipe',
	pure: false
})
export class DynamicPipe implements PipeTransform {


	/**
	 * 생성자
	 * @param translate 번역 서비스 객체
	 */
	constructor(
		translate: TranslateService,
	) {
		this.translate = translate;
	}

	/**
	 * 색상 프로퍼티명
	 */
	public static COLOR_PROPERTY_NAME: string = 'Colors';

	/**
	 * 번역 서비스 객체
	 */
	public translate: TranslateService;

	/**
	 * transform 구현
	 * @param item 아이템 객체
	 * @param modifier 변환 함수
	 * @param param 파라미터
	 * @param field 필드명
	 * @param useHighlight 하이라이트 사용 여부
	 */
	transform(item: any, modifier: string, param?: any, field?: string, useHighlight?: boolean): any {

		// 값이 없을 경우
		if (item === null) {
			return '';
		}

		if (typeof useHighlight === 'undefined') {
			useHighlight = true;
		}

		const isHighlight = field && item[DynamicPipe.COLOR_PROPERTY_NAME] && useHighlight ? true : false;

		const value: any = field ? this.getValue(item, field) : item;

		let result: any;

		// enum 값과 modifier가 모두 있는 경우
		if (modifier && (param !== undefined && param !== null)) {
			// tslint:disable-next-line:no-eval
			result = eval('this.' + modifier + '(value ,param)');
		}
		// modifier만 있는 경우
		else if (modifier) {
			// tslint:disable-next-line:no-eval
			result = eval('this.' + modifier + '(value)');
		}
		// 모두 없는 경우
		else {
			result = value;
		}

		// 하이라이트 설정인 경우
		if (isHighlight) {
			result = this.setHighlight(item[DynamicPipe.COLOR_PROPERTY_NAME], field, result);
		}

		return result;
	}

	/**
	 * 아이템에서 해당 컬럼의 값을 추출
	 * @param item 아이템
	 * @param field 값을 추출할 컬럼명
	 */
	public getValue(item: any, field: string): any {
		const fields: string[] = field.split('.');
		let value: any = item[fields[0]];

		for (let i = 1; i < fields.length; i++) {
			value = value[fields[i]];
		}

		return value;
	}

	/**
	 * 사용자 템플릿을 html형식으로 반환한다
	 * @param value 변환할 문자열
	 * @param template 템플릿 문자열
	 */
	public customTemplate(value: string, template: string): string {
		return template.replace('{0}', value);
	}

	/**
	 * 두 가지 필드가 있을때 받아온 프러퍼티 값으로 머징 시킨다
	 * @param value 객체
	 * @param targetPropertiesString 처리할 속성명
	 */
	public mergeObject(value: any, targetPropertiesString: string): string {
		// , 값으로 프로퍼티를 분리
		const targetProperties: string[] = targetPropertiesString.split(',');
		let result: string = '';

		// 넘어온값이 배열인경우
		if (value instanceof Array) {
			value.forEach((item: any, index: number) => {
				// 받아온 오브젝트를 합친다
				for (const property of targetProperties) {
					// 배열이라면
					if (item[property] instanceof Array) {
						result = result + item[property].join('<br>');
					}
					// 오브젝트라면
					else {

						if (typeof item[property] === 'undefined') {
							continue;
						}

						if (item[property] !== '') {
							if (item[property].split(',').length > 1) {
								result = result + item[property].split(',').join('<br>') + '<br>';
							} else {
								result = result + item[property] + '<br>';
							}
						}
					}
				}

				if (result.indexOf('undefined') > -1) {
					result = '';
				}
			});

		} else {

			// 받아온 오브젝트를 합친다
			for (const property of targetProperties) {
				// 배열이라면
				if (value[property] instanceof Array) {
					result = result + value[property].join('<br>');
				}
				// 오브젝트라면
				else {

					if (typeof (value[property]) !== 'undefined') {
						result = result + value[property].split(',').join('<br>');
					}
				}
			}

			if (result.indexOf('undefined') > -1) {
				result = '';
			}
		}

		return result;
	}

	/**
	 * 하이라이트를 설정한다
	 * @param item 처리할 아이템 객체
	 * @param field 필드명
	 * @param value 값
	 */
	public setHighlight(item: any, field: string, value: string): string {
		const COLOR_PROPERTY_NAME: string = 'Color';
		let result: string = '';
		let hasValue: boolean = false;

		try {

			// color가 설정된 필드가 있는지 확인
			Object.keys(item).forEach((value, index) => {
				if ((typeof (item[value]) !== 'object' && item[value] !== null && item[value] !== undefined && item[value] !== '' && value !== COLOR_PROPERTY_NAME) || (typeof (item[value]) === 'object' && item[value].length > 0)) {
					hasValue = true;
				}
			});

			// 현재 필드에 값이 있을 경우
			if (hasValue && item[field] !== null && item[field] !== undefined && item[field] !== '') {
				// 필드 값이 배열인 경우
				if (typeof (item[field]) === 'object' && item[field].length >= 0) {
					// 현재 값을 split 한다
					const values: string[] = value.split(',');

					// 배열 값에 해당하는 부분을 찾아서 해당 부분만 색을 넣는다
					item[field].forEach((color: any) => {
						const index = values.findIndex(i => i.trim() === color.Name);
						if (index >= 0) {
							values[index] = '<span style="color:' + color.Value + '">' + color.Name + '</span>';
						}
					});

					result = values.join(',');
				}
				// 일반 값일 경우
				else {
					result = '<span style="color:' + item[field] + '">' + value + '</span>';
				}
			}
			// 모든 값에 color가 설정되어 있지 않고, color 필드에 값이 있을 경우
			else if (!hasValue && item[COLOR_PROPERTY_NAME] !== '') {
				result = '<span style="color:' + item[COLOR_PROPERTY_NAME] + '">' + value + '</span>';
			} else {
				result = value;
			}
		} catch (e) {
		}

		return result;
	}

	/**
	 * 날짜를 포메팅 한다
	 * @param value 처리할 날짜 문자열
	 */
	formatDate(value: string): string {
		if (!value) {
			return '';
		}

		return value.toLongDateString();
	}

	/**
	 * 날짜 포메팅(yyyy-MM-dd)
	 * @param value 처리할 날짜 문자열
	 */
	formatShortDate(value: string): string {
		if (!value) {
			return '';
		}

		return value.toShortDateString();
	}


	/**
	 * 배열 속의 객체들의 특정 속성 값을 개행 시킨다.
	 * @param value 원본 객체 배열
	 * @param targetPropertieName 속성명
	 */
	toMultiLineWithValue(value: Array<any>, targetPropertieName: string): string {
		let result: string = '';

		if (typeof value === 'undefined') {
			return '';
		}
		if (value.length === 0) {
			return '';
		}

		for (let i = 0; i < value.length; i++) {
			result += value[i][targetPropertieName];
			if (i !== (value.length - 1)) {
				result += '<br>';
			}
		}

		return result;
	}

	/**
	 * 배열 속의 객체들의 특정 속성 값을 ','로 연결하여 반환한다.
	 * @param value 객체 배열
	 * @param targetPropertieName 속성명
	 */
	toCommaWithValue(value: Array<any>, targetPropertieName: string): string {
		if (typeof value === 'undefined') {
			return '';
		}
		if (value.length === 0) {
			return '';
		}

		return value.map(x => x[targetPropertieName]).join(',');
	}


	/**
	 * 문자열 배열을 ','으로 연결한 문자열로 반환한다.
	 * @param value 문자열 배열
	 */
	toCommaWithStringValue(value: Array<string>): string {
		if (typeof value === 'undefined') {
			return '';
		}
		if (value.length === 0) {
			return '';
		}

		return value.join(',');
	}


	/**
	 * 객체 배열의 Name 값을 ','로 이어 문자열로 만든다.
	 * @param value 객체 배열
	 */
	toCommaWithName(value: Array<any>): string {
		if (typeof value === 'undefined') {
			return '';
		}
		if (value.length === 0) {
			return '';
		}

		return value.map(i => i.Name).join(',');
	}

	/**
	 * Enum 타입과 맞는 DisplayName 을 출력한다
	 * @param value Enum 값
	 * @param enumClass Enum 클래스
	 */
	getEnumTranslate(value: any, enumClass: any): string {
		if (value === 'undefined') {
			return '';
		}

		if (value === 99) {
			return '';
		}

		if (value === null) {
			return '';
		}

		if (enumClass) {
			let translated: string = '';
			if (enumClass.hasOwnProperty('toDisplayShortName')) {
				this.translate.get(enumClass.toDisplayShortName(value)).subscribe(res => {
					translated = res;
				});
			} else {
				translated = enumClass[value];
			}
			return translated;
		} else {
			return '';
		}
	}

	/**
	 * 천단위 콤마
	 * @param num 변환할 숫자값
	 */
	public numberWithCommas(num: number, fractionDigits: number = 1): string {
		if(num) {
			const parts: string[] = num.toFixed(fractionDigits).toString().split('.');
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			return parts.join('.');
		}
		else {
			return '0';
		}
	}

	/**
	 * 천단위 콤마
	 * @param num 변환할 숫자값
	 */
	public numberWithStatistics(num: number, fractionDigits: number = 1): string {
		if(!num)
			return '-';
		else {
			let numString: string;

			// 소수점이 존재하는 경우
			if(num - Math.floor(num) > 0)
				numString = num.toFixed(fractionDigits);
			else
				numString = num.toString();

			const parts: string[] = numString.split('.');
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			return parts.join('.');
		}

	}

	/**
	 * 천단위 콤마
	 * @param num 변환할 숫자값
	 */
	public dashOnEmpty(value: any): string {
		if(!value)
			return '-';
		else
			return value;
	}
}

/**
 * DynamicPipe 선언
 */
export const DYNAMIC_PIPES = [DynamicPipe];
