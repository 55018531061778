/**
 * 전역 확장 메서드
 */
interface String {
	isEmpty(): boolean;

	formatNumber(): string;

	getComputedDate(): string;

	toShortDateString(): string;

	toLongDateString(): string;

	toSeparateComma(): string;
}

/**
 * 확장 - 값이 비어있는지를 체크한다
 */
String.prototype.isEmpty = function(): boolean {
	return this == null || this.length === 0;
};

/**
 * 확장 - 1000 -> 1,000 숫자형 String 값을 Comma 처리 한다
 */
String.prototype.toSeparateComma = function(): string {
	return this.replace(/\B(?=(\d{3})+(?!\d))/g,',');
};


/**
 * yyyy-MM-dd 형식의 문자열로 변환
 * Date 타입에 담겨있지만 문자열로 취급되는 경우 오류를 방지하기 위함.
 */
String.prototype.toShortDateString = function(): string {
	// "2018-05-09 15:10:48" 형식으로 올 경우 IE에서 parse error 발생, 에러 방지 위해 "2018-05-09T15:10:48"형식으로 변경
	const date = this.replace(/^(.*-[0-9][0-9])(\ )([0-9][0-9]\:.*$)/, '$1T$3');
	const d = Date.parse(date);

	// 숫자가 아닌 경우 Date가 아니기 때문에 의도한 결과가 나오지 않을 것이므로 빈 문자열을 반환함.
	if (isNaN(d)) {
		return '';
	}

	const dd = new Date(d);

	return dd.getFullYear() + '-' +
		('0' + (dd.getMonth() + 1)).slice(-2) + '-' +
		('0' + dd.getDate()).slice(-2);
};

/**
 * yyyy-MM-dd HH:mm:ss 형식의 문자열로 변환
 * Date 타입에 담겨있지만 문자열로 취급되는 경우 오류를 방지하기 위함.
 */
String.prototype.toLongDateString = function(): string {
	// "2018-05-09 15:10:48" 형식으로 올 경우 IE에서 parse error 발생, 에러 방지 위해 "2018-05-09T15:10:48"형식으로 변경
	const date = this.replace(/^(.*-[0-9][0-9])(\ )([0-9][0-9]\:.*$)/, '$1T$3');
	const d = Date.parse(date);

	// 숫자가 아닌 경우 Date가 아니기 때문에 의도한 결과가 나오지 않을 것이므로 빈 문자열을 반환함.
	if (isNaN(d)) {
		return '';
	}

	const dd = new Date(d);

	return dd.getFullYear() + '-' +
		('0' + (dd.getMonth() + 1)).slice(-2) + '-' +
		('0' + dd.getDate()).slice(-2) + ' ' +
		('0' + dd.getHours()).slice(-2) + ':' +
		('0' + dd.getMinutes()).slice(-2) + ':' +
		('0' + dd.getSeconds()).slice(-2);
};

String.prototype.formatNumber = function() : string {
	return this.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

String.prototype.getComputedDate = function() : string {
	return new Date(this.toString()).getComputedDate();
};


