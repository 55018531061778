import {Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-tabs-header',
  templateUrl: './tabs-header.component.html',
  styleUrls: ['./tabs-header.component.scss']
})
export class TabsHeaderComponent implements AfterViewInit {
	/**
	 * 부모로 전달할 이벤트 Emitter
	 */
	@Output() clickTabEvent = new EventEmitter();
	/**
	 * 탭명
	 */
	@Input() public tabName: string = '';
	/**
	 * 부모로 전달할 Input 객체
	 */
	@ViewChild('inputTag') inputTag: ElementRef;
	/**
	 * 부모로 전달할 Input 객체
	 */
	@Output() inputTagElement: ElementRef;
	/**
	 * 생성자
	 */
	constructor() { }
	/**
	 *  ngAfterViewInit
	 */
	ngAfterViewInit() {
		this.inputTagElement = this.inputTag;
	}
	/**
	 * 탭클릭시 이벤트 발생
	 * @param $event 이벤트 객체
	 */
	onClickTab($event: any) {
		this.inputTag.nativeElement.checked = false;
		this.clickTabEvent.emit(this.inputTag);
	}
}
