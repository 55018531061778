import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectedEventArgs} from '@syncfusion/ej2-inputs';
import {EnumResponseResult} from '../../models/enums/enum-response-result.model';
import {RequestMoveImage} from '../../models/request/common/request-move-image.model';
import {RequestMoveImages} from '../../models/request/common/request-move-images.model';
import {ResponseUpload} from '../../models/response/common/response-upload.model';
import {createElement} from '@syncfusion/ej2-base';
import {UploadProviderProvider} from '../../services/providers/upload.provider';
import {ToastMessageService} from '../../services/toast-message.service';
import {LocalCacheService} from '../../services/cache/cache.service';
import {AuthService} from '../../services/auth.service';
import {ResponseLogin} from '../../models/response/account/response-login.model';
import {environment} from '../../../environments/environment';
import {RequestCommunityPost} from '../../models/request/community/request-community-post.mode';
import {RequestCommunityPostImage} from '../../models/request/community/request-community-post-image.model';
import {Observable, Subscription} from 'rxjs';

@Component({
	selector: 'app-post-write',
	templateUrl: './post-write.component.html',
	styleUrls: ['./post-write.component.scss']
})
export class PostWriteComponent implements OnInit {
	// 프로필 이미지
	profileImage: string = '';

	// 사용자명
	userName: string = '';

	// 이미지 드래그중 여부 ( Hover )
	isOnDragInContent: boolean = false;

	@ViewChild('inputBoxRef', { static: true }) inputBoxRef: ElementRef;

	inputBox: HTMLElement = null;

	// 이미지 드랍 범위
	@ViewChild('droparea', { static: true }) dropAreaElementRef: ElementRef;

	// 확인 버튼 클릭시 전달할 이벤트
	@Output() sendContents = new EventEmitter<RequestCommunityPost>();

	// 사용중인 Parent 컴포넌트로 부터 전달받을 게시 완료 이벤트
	@Input() sendContentsDone: Observable<void>;

	sendContentsDoneSubscription: Subscription;

	// option: 그냥 뿌려질 html
	@Input() defaultContents: string = '';

	// 스켈레톤 출력 여부
	@Input() isSkeleton: boolean = false;

	@Input() loginUserInfo: ResponseLogin;

	// dropArea Html 엘리먼트
	dropArea: HTMLElement;

	// 사진 사이트 Url
	photoSiteUrl: string = '';
	contentsImages: RequestCommunityPostImage[] = [];

	constructor(private uploadProviderProvider:UploadProviderProvider,
				private messageService: ToastMessageService,
				private localCacheService: LocalCacheService,
				private authService: AuthService,
				) {
		this.isSkeleton = false;
	}

	ngOnInit(): void {
		this.sendContentsDoneSubscription = this.sendContents.subscribe(() => {
			this.dropArea.children.item(0).innerHTML = '';
			this.contentsImages = [];
		});

		this.photoSiteUrl = `${environment.domain}`;

		this.authService.getLogin()
			.subscribe(async (response) => {
				if(response.Result === EnumResponseResult.Success) {
					this.loginUserInfo = response.Data;
					this.dropArea = this.dropAreaElementRef.nativeElement as HTMLElement;

					if(this.loginUserInfo.Profile) {
						this.profileImage = `${this.loginUserInfo.Profile.PhotoUrl}`;
					}
					this.userName = this.loginUserInfo.DisplayName;

					if(this.inputBox === null)
						this.inputBox = this.inputBoxRef.nativeElement as HTMLElement;

					this.inputBox.setAttribute('placeholder', `${this.userName}  님, 어떤생각을 하고 계세요?`);
				}
			});

	}

	/**
	 * dom 에 파일이 드랍된경우
	 * @param args 이벤트 객체
	 */
	async onFileSelect(args : SelectedEventArgs)  {


		// 이미지가 표시될 root dom 을 가져온다
		const imageRootArea: HTMLElement = (document.getElementsByClassName('image-area')[document.getElementsByClassName('image-area').length-1] as HTMLElement);

		// 이미지 리더
		const reader = new FileReader();

		// 전체 파일에 대해 처리한다
		args.filesData.forEach(file => {
			reader.readAsBinaryString(file.rawFile as Blob);
			reader.onloadend = () => {
				const base64data = btoa(reader.result as string);

				// 이미지 Area 가 존재하는 경우
				if(imageRootArea) {

					// 파일을 서버로 전송한다 ( Temp 생성 )
					this.uploadProviderProvider.saveFile(file.type ,file.name ,base64data)
						.subscribe((response) => {
							// 결과가 성공인 경우
							if(response.Result === EnumResponseResult.Success)   {

								const fileName = new RequestMoveImage();
								fileName.UserFileName = response.Data.Items[0].UserFileName;
								fileName.ServerFileName = response.Data.Items[0].ServerFileName;

								const result = new RequestCommunityPostImage();
								result.ImageFileName =  response.Data.Items[0].UserFileName;
								result.ImageServerPath = response.Data.Items[0].ServerFileName;

								this.contentsImages.push(result);


								const requestMoveImages = new RequestMoveImages();
								requestMoveImages.FileNames = [];
								requestMoveImages.FileNames.push(fileName);

								// Url 사을 할수 있도록 처리한다
								this.uploadProviderProvider.moveImages(requestMoveImages)
									.subscribe(response => {


										// 결과가 성공인 경우
										if(response.Result === EnumResponseResult.Success)   {
											const data = response.Data.Items[0] as ResponseUpload;

											const image = createElement('div', {
												className: 'temp-image',
												innerHTML: `<div class="temp-image-view"><img alt="user-temp-image" src="${data.ServerFileName}" /></div>`
											});

											imageRootArea.appendChild(image);
										}
										// 결과가 실패 인경우
										else
											this.messageService.error('[' + response.Code + '] ' + response.Message);
									});
							}
							// 결과가 실패 인경우
							else
								this.messageService.error('[' + response.Code + '] ' + response.Message);
						});
				}
			};
		});
	}

	getUserPost() {
		let contents = this.dropArea.children.item(0).innerHTML;
		const images = this.dropArea.children.item(1).children;

		let imageContents = '';

		Array.prototype.forEach.call(images,(image) => {
			imageContents += `<div>${image.innerHTML}</div>`;
		});

		if(imageContents !== '') {
			contents += `<div>${imageContents}</div>`;
		}

		return contents;
	}

	get isCanPost() {
		return this.getUserPost() !== '';
	}

	// 내용을 포스팅 한다
	sendUserPost() {



		const dispatch = new RequestCommunityPost();
		dispatch.Content = this.getUserPost();
		dispatch.CommunityPostImages = this.contentsImages;
		dispatch.ProfilePhotoUrl = this.loginUserInfo.Profile.PhotoUrl;

		// 부모 컨테이너에게 결과 값을 전달 한다
		this.sendContents.emit(dispatch);
	}
}
