import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {catchError, delay, map, mergeMap, retryWhen, take} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {EnumResponseResult} from '../models/enums/enum-response-result.model';
import {MessageNotificationService} from './notification.service';

@Injectable({providedIn: 'root'})
export class HttpServiceInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private authService: AuthService,
		private messageService: MessageNotificationService,
	) {
	}

	// http request interceptor
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let req: HttpRequest<any>;
		const currentUrl = request.url.toLowerCase();

		// 파일 업로드 경우
		if (currentUrl.includes('/api/v1/upload') || currentUrl.startsWith('./assets/') || currentUrl.startsWith('/assets/')) {
			req = request;
		} else {
			req = request.clone({
				headers: request.headers.set('Content-Type', 'application/json; charset=utf-8'),
				withCredentials: true
			});
		}

		return next.handle(req)
			.pipe(
				retryWhen(errors => {

					const maxRetries = 3;
					let retryCount = 0;

					return errors
						.pipe(
							delay(2000)
						)
						.pipe(
							mergeMap(error => {
								return error.status < 500 || ++retryCount >= maxRetries ? throwError(error) : of(error);
							}),
							take(maxRetries)
						);
				}),
				map((event: HttpEvent<any>) => {
					return event;
				}),
				catchError(err => {
					this.errorHandler(err);
					return throwError(err);
				})
			);
	}

	// 통신 에러 핸들러
	public errorHandler(err: HttpErrorResponse) {
		if (err) {
			switch (err.status) {
				case 200:
					break;
				case 403:
				case 404:
				case 401:
					this.authService.checkLogin().subscribe((response) => {
						if (response.Result === EnumResponseResult.Error) {
							this.messageService.error('[' + response.Code + '] ' + response.Message);
							this.router.navigate(['/account/login']);
						}
					});
					break;
				case 400:
				case 500:
				case 503:
				case 504:
					this.router.navigate(['/errors/' + err.status]);
					break;
				default:
					this.router.navigate(['/account/login']);
					break;
			}
		}
	}
}
