import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, timer} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CommunityProvider} from '../community.provider';

@Injectable({
	providedIn: 'root'
})
export class CommunityValidatorProvider {
	constructor(private communityProvider:CommunityProvider) {
	}

	/**
	 * 존재하는 커뮤니티 명인지 체크 true : 사용가능
	 */
	existNameAsync(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
			return timer(1000)
				.pipe(
					switchMap(() => {
						return this.communityProvider.isUniqueNameAsync(' ',{
								Name:control.value
							})
							.pipe(
								map(res => {
									if(res.Data)
										return null;
									else
										return { existNameAsync : true };
								})
							);
					})
				);
		};
	}


	/**
	 * 존재하는 Uri 명인지 체크
	 */
	existUriAsync(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
			return timer(1000)
				.pipe(
					switchMap(() => {
						return this.communityProvider.isUniqueUriAsync(' ',{
								Uri:control.value
							})
							.pipe(
								map(res => {
									if(res.Data)
										return null;
									else
										return { existUriAsync : true };
								})
							);
					})
				);
		};
	}


	/**
	 * 존재하는 커뮤니티 명인지 체크 true : 사용가능
	 */
	existNameModifyAsync(existValue:string): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
			// 동일한 이름으로 요청했을 경우
			if(control.value === existValue)
				return new Observable(null);


			return timer(1000)
				.pipe(
					switchMap(() => {

						return this.communityProvider.isUniqueNameAsync(' ',{
								Name:control.value
							})
							.pipe(
								map(res => {
									if(res.Data)
										return null;
									else
										return { existNameAsync : true };
								})
							);
					})
				);
		};
	}


	/**
	 * 존재하는 Uri 명인지 체크
	 */
	existUriModifyAsync(existValue:string): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
			// 동일한 이름으로 요청했을 경우
			if(control.value === existValue)
				return new Observable(null);

			return timer(1000)
				.pipe(
					switchMap(() => {
						return this.communityProvider.isUniqueUriAsync(' ',{
								Uri:control.value
							})
							.pipe(
								map(res => {
									if(res.Data)
										return null;
									else
										return { existUriAsync : true };
								})
							);
					})
				);
		};
	}


	/**
	 * 기존 정보와 같은 경우 Invalid 처리
	 * @param existValue 기존정보
	 */
	requireNotSameValue(existValue:string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if(existValue === control.value)
				return { requireNotSameValue: true };
			else
				return null;
		};
	}
}

