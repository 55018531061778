export enum EnumDataInputMethod {
	Device,
	Manual,
}

export namespace EnumDataInputMethod {

	export function toDisplayName(value: EnumDataInputMethod) {
		switch (value) {
			case EnumDataInputMethod.Device:
				return 'UL_USER_DATA_INPUT_METHOD_DEVICE';
			case EnumDataInputMethod.Manual:
				return 'UL_USER_DATA_INPUT_METHOD_MANUAL';
			default:
				return value;
		}
	}

	export function toDisplayDescription(value: EnumDataInputMethod) {
		switch (value) {
			case EnumDataInputMethod.Device:
				return 'UL_USER_DATA_INPUT_METHOD_DEVICE';
			case EnumDataInputMethod.Manual:
				return 'UL_USER_DATA_INPUT_METHOD_MANUAL';
			default:
				return value;
		}
	}

	export function toDisplayGroupName(_: EnumDataInputMethod) {
		return 'UL_USER_DATA_INPUT_METHOD';
	}

	export function toDisplayShortName(value: EnumDataInputMethod) {
		switch (value) {
			case EnumDataInputMethod.Device:
				return 'UL_USER_DATA_INPUT_METHOD_DEVICE';
			case EnumDataInputMethod.Manual:
				return 'UL_USER_DATA_INPUT_METHOD_MANUAL';
			default:
				return value;
		}
	}

	export function toDisplayPrompt(value: EnumDataInputMethod) {
		switch (value) {
			case EnumDataInputMethod.Device:
				return 'UL_USER_DATA_INPUT_METHOD_DEVICE';
			case EnumDataInputMethod.Manual:
				return 'UL_USER_DATA_INPUT_METHOD_MANUAL';
			default:
				return value;
		}
	}
}
