import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CodeInputComponent} from 'angular-code-input';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';

@Component({
	selector: 'app-code-input',
	templateUrl: './shared-code-input.component.html',
	styleUrls: ['./shared-code-input.component.scss']
})
export class SharedCodeInputComponent implements OnInit {
	// 코드 변경이벤트 Emitter
	@Output() codeChangedEmitter = new EventEmitter<string>();

	// 코드 입력완료시 Emitter
	@Output() codeCompletedEmitter = new EventEmitter<string>();

	// 취소 이벤트 Emitter
	@Output() cancelEmitter = new EventEmitter();

	// 코드 길이
	@Input() codeLength = 6;

	// 코드 입력 숨김 처리여부
	@Input() isCodeHidden = true;

	// 입력창 Element
	@ViewChild('codeInput') codeInput !: CodeInputComponent;

	// 자기 자신
	@ViewChild('codeInputModal') codeInputModal: DialogComponent;

	constructor() {
	}

	/**
	 *  ngOnInit
	 */
	ngOnInit(): void {
		// 초기진입시 코드 입력 초기화
		if(this.codeInput)
			this.codeInput.reset();
	}

	/**
	 * 취소 버튼 클릭시
	 */
	closeDialog() {
		this.codeInputModal.hide();
	}

	/**
	 * 코드 입력 변경시
	 * @param code 입력중인 코드
	 */
	onCodeChanged(code: string) {
		this.codeChangedEmitter.emit(code);
	}

	/**
	 * 코드 입력 완료시
	 * @param code 입력한 코드
	 */
	onCodeCompleted(code: string) {
		this.codeCompletedEmitter.emit(code);
	}

	/**
	 * 다이얼 로그 오픈
	 */
	openDialog() {
		// 초기진입시 코드 입력 초기화
		if(this.codeInput)
			this.codeInput.reset();

		this.codeInputModal.show();
	}
}
