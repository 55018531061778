import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-calorie-line-bar',
	templateUrl: './calorie-line-bar.component.html',
	styleUrls: ['./calorie-line-bar.component.scss']
})
export class CalorieLineBarComponent implements OnInit {
	/**
	 * 타이틀
	 */
	@Input() title: string = '';
	/**
	 * 값
	 */
	@Input() value: string = '';
	/**
	 * 지방비율
	 */
	@Input() fatPercent: string = '0%';
	/**
	 * 탄수화물 비율
	 */
	@Input() carbohydratePercent: string = '0%';
	/**
	 * 단백질비율
	 */
	@Input() proteinPercent: string = '0%';

	constructor() {

	}
	ngOnInit() {

	}
}
