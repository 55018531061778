import {Injectable} from '@angular/core';
import {createSpinner, hideSpinner, showSpinner} from '@syncfusion/ej2-angular-popups';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {OverlayComponent} from '../components/overlay/overlay.component';

@Injectable({providedIn: 'root'})
export class LoadingService {
	/**
	 * 오버레이 객체
	 * @private
	 */
	private overlayRef: OverlayRef;

	/**
	 * 스피너 객체
	 * @private
	 */
	private spinnerElement: HTMLElement;

	/**
	 * 생성자
	 * @param overlay 오버레이 객체
	 */
	constructor(
		private overlay: Overlay,
	) {
	}

	/**
	 * dom 에 로딩 바를 표시한다
	 * @param domTargetId target 아이디 없는경우 기본 지정
	 * @param defaultHideSeconds 로딩 바 기본 hide 초
	 */
	public showLoading(domTargetId?: string, defaultHideSeconds = 2) {
		this.showOverlay();
		this.showLoadingSpinner(domTargetId);
	}

	/**
	 * dom 에 로딩 바를 제거 한다
	 */
	public hideLoading() {
		this.hideOverlay();
		this.hideLoadingSpinner();
	}

	/**
	 * dom 에 로딩 바를 제거한다
	 */
	public hideLoadingSpinner() {
		// 스피너 제거
		hideSpinner(this.spinnerElement);
	}

	/**
	 * 스피너를 추가한다
	 * @param domeTargetId target 아이디 없는경우 기본 지정
	 */
	public showLoadingSpinner(domeTargetId?: string) {
		if (domeTargetId == null)
			this.spinnerElement = document.getElementById('container') as HTMLElement;
		else
			this.spinnerElement = document.getElementById(domeTargetId) as HTMLElement;

		// 스피너 추가
		createSpinner({target: this.spinnerElement});
		showSpinner(this.spinnerElement);
	}

	/**
	 * 컴포넌트에 Overlay 를 씌운다
	 */
	public showOverlay() {
		if(this.overlayRef === undefined) {
			// 오버레이 객체 생성
			this.overlayRef = this.overlay.create({
				// 브라우저 ViewPort 기준
				positionStrategy: this.overlay.position().global(),
				// 백드랍 사용
				hasBackdrop: true,
				// 기본 Backdrop 컬러 블랙으로 설정 , 투명화게 원할시 dk-overlay-transparent-backdrop
				backdropClass: 'cdk-overlay-dark-backdrop',
				// Overlay 사용시 스크롤링 되지 않도록 설정
				scrollStrategy: this.overlay.scrollStrategies.block(),
			});
		}

		this.overlayRef.attach(new ComponentPortal(OverlayComponent, null));
	}

	/**
	 * 컴포넌트에 Overlay 를 제거한다
	 */
	public hideOverlay() {
		this.overlayRef.dispose();
		this.overlayRef = undefined;
	}
}
