// import {NotificationService} from '@progress/kendo-angular-notification';
import {Injectable} from '@angular/core';

@Injectable()
export class MessageNotificationService {

	// // 알람 서비스
	// private _notificationService: NotificationService = null;

	constructor(
		// notificationService: NotificationService
	) {
		// this._notificationService = notificationService;
	}

	// Shortcut methods
	success(content: string, hideAfter: number = 3): any {
		return this.create('success', content, hideAfter);
	}

	error(content: string, hideAfter: number = 6): any {
		return this.create('error', content, hideAfter);
	}

	info(content: string, hideAfter: number = 3): any {
		return this.create('info', content, hideAfter);
	}

	warning(content: string, hideAfter: number = 3): any {
		return this.create('warning', content, hideAfter);
	}

	create(type: 'none' | 'success' | 'warning' | 'error' | 'info', content: string, hideAfter: number = 0): any {
		// this._notificationService.show({
		// 	animation: {type: 'fade', duration: 800},
		// 	type: {style: type, icon: true},
		// 	content,
		// 	position: {horizontal: 'center', vertical: 'top'},
		// 	hideAfter: hideAfter * 1000,
		// 	closable: hideAfter === 0 ? true : false
		// });
	}
}
