import {LOCALE_ID, NgModule} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OverlayComponent} from '../shared/components/overlay/overlay.component';
import {LocalCacheService, SessionCacheService} from '../shared/services/cache/cache.service';
import {AuthService} from '../shared/services/auth.service';
import {AuthGuard} from '../shared/services/auth.guard';
import * as moment from 'moment';
import {HttpServiceInterceptor} from '../shared/services/http-service.interceptor';
import {LoadingService} from '../shared/services/loading.service';
import {MessageNotificationService} from '../shared/services/notification.service';
import {ToastModule} from '@syncfusion/ej2-angular-notifications';
import {ToastMessageService} from '../shared/services/toast-message.service';
import {SharedModule} from '../shared/shared.module';
import localeKo from '@angular/common/locales/ko';

import '../shared/extensions/number.extension';
import {registerLocaleData} from '@angular/common';
import { AppLinkComponent } from './app-link/app-link.component';

/**
 * 번역 객체 로더 메서드
 * @param http 통신 객체
 */
export function createTranslateLoader(http: HttpClient) {
	// 경로 밎 파일명 지정
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
	declarations: [
		AppComponent,
		OverlayComponent,
  AppLinkComponent,
	],
	imports: [
		ToastModule,
		BrowserModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			},
			// 기본언어로 한국어 선택
			defaultLanguage: 'ko'
		}),
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		OverlayModule,
		PortalModule,
	],
	providers: [
		LocalCacheService,
		SessionCacheService,
		AuthService,
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpServiceInterceptor,
			multi: true
		},
		MessageNotificationService,
		LoadingService,
		ToastMessageService,
		{ provide: LOCALE_ID, useValue: 'ko' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	/**
	 * 생성자
	 * @param translateService 번역 모듈
	 */
	constructor(private translateService: TranslateService) {
		// 언어 번역 설정
		this.translateService.addLangs(['en', 'ko']);
		this.translateService.setDefaultLang('ko');

		// 브라우저 설정에 따라 언어 변경
		const browserLang = this.translateService.getBrowserLang();
		this.translateService.use(browserLang.match(/en|ko/) ? browserLang : 'ko');

		// Date pipe 설정은 한국어를 기본으로 한다
		registerLocaleData(localeKo);

		moment.locale(this.translateService.getDefaultLang());
	}
}
