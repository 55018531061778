import { NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {ButtonModule, CheckBoxModule} from '@syncfusion/ej2-angular-buttons';
import {TextBoxModule, UploaderAllModule} from '@syncfusion/ej2-angular-inputs';
import {TabModule, AccordionModule} from '@syncfusion/ej2-angular-navigations';
import {FooterComponent} from './components/layouts/footer/footer.component';
import {HeaderComponent} from './components/layouts/header/header.component';
import {RouterModule} from '@angular/router';
import {ForgotComponent} from '../app/accounts/forgot/forgot.component';
import {TranslateModule} from '@ngx-translate/core';
import '../shared/extensions/date.extension';
import '../shared/extensions/number.extension';
import '../shared/extensions/string.extension';
import { SafeHtmlPipe} from './pipes/safe-html.pipe.ts.pipe';
import {ListViewModule} from '@syncfusion/ej2-angular-lists';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
	AccumulationDataLabelService,
	AccumulationTooltipService,
	AreaSeriesService,
	CategoryService,
	ChartAllModule,
	ChartAnnotationService,
	ChartModule,
	ColumnSeriesService,
	DataLabelService,
	DateTimeCategoryService,
	DateTimeService,
	LegendService,
	LineSeriesService, PieSeriesService,
	RangeAreaSeriesService,
	ScatterSeriesService, ScrollBarService,
	SelectionService, SplineAreaSeriesService,
	SplineSeriesService,
	StackingColumnSeriesService,
	StepAreaSeriesService,
	StripLineService,
	TooltipService,
	ZoomService
} from '@syncfusion/ej2-angular-charts';
import { TabsHeaderComponent } from './components/tabs/header/tabs-header.component';
import { TabGroupComponent } from './components/tabs/tab-group.component';
import { TabsContentComponent } from './components/tabs/content/tabs-content.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { CalorieLineBarComponent } from '../app/healthcare/food-lens/calorie-line-bar/calorie-line-bar.component';
import { ScrollViewNoDataComponent } from './components/scroll-view-no-data/scroll-view-no-data.component';
import { SyncfusionChartNoDataComponent } from './components/syncfusion-chart-no-data/syncfusion-chart-no-data.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {CommonDynamicPipeModule} from './modules/dynamic-pipe.module';
import {ChartGlucoseProvider} from './services/providers/charts/chart-glucose.provider';
import {ChartBloodPressureProviderProvider} from './services/providers/charts/chart-blood-pressure.provider';
import { SwiperModule } from 'swiper/angular';
import {CommunityValidatorProvider} from './services/providers/custom-validators/community.validator.provider';
import { PostWriteComponent } from './components/post-write/post-write.component';
import {DatePickerModule} from '@syncfusion/ej2-angular-calendars';
import { SharedCodeInputComponent } from './components/code-input/shared-code-input.component';
import {CodeInputModule} from 'angular-code-input';
/**
 * 각 Child 모듈들 에서 공통으로 import 할 모듈
 * Syncfusion 등의 UI 컴포넌트 등을 공통으로 정의 한다
 */
@NgModule({
	declarations: [
		FooterComponent,
		HeaderComponent,
		ForgotComponent,
		SafeHtmlPipe,
		TabsHeaderComponent,
		TabGroupComponent,
		TabsContentComponent,
		CalorieLineBarComponent,
		ScrollViewNoDataComponent,
		SyncfusionChartNoDataComponent,
		ChangePasswordComponent,
  PostWriteComponent,
  SharedCodeInputComponent,
	],
	exports: [
		// Angular UI 모듈
		CommonModule,
		ReactiveFormsModule,
		OverlayModule,
		PortalModule,
		ForgotComponent,
		InfiniteScrollModule,
		NgxSkeletonLoaderModule,
		// Syncfusion UI 모듈
		ButtonModule,
		CheckBoxModule,
		TextBoxModule,
		TabModule,
		AccordionModule,
		ChartModule,
		ListViewModule,
		HeaderComponent,
		FooterComponent,
		SafeHtmlPipe,
		DropDownListModule,
		TabsHeaderComponent,
		TabGroupComponent,
		TabsContentComponent,
		CalorieLineBarComponent,
		ScrollViewNoDataComponent,
		SyncfusionChartNoDataComponent,
		DatePickerModule,
		// 화면 컴포넌트
		ChangePasswordComponent,
		CommonDynamicPipeModule,
		SwiperModule,
		PostWriteComponent,
		DialogModule,
		CodeInputModule,
		SharedCodeInputComponent,
	],
	imports: [
		// Angular UI 모듈
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		OverlayModule,
		PortalModule,
		RouterModule,
		InfiniteScrollModule,
		NgxSkeletonLoaderModule,
		// Syncfusion UI 모듈
		ButtonModule,
		CheckBoxModule,
		TextBoxModule,
		ChartModule,
		ChartAllModule,
		TranslateModule,
		ListViewModule,
		DropDownListModule,
		AccordionModule,
		DialogModule,
		DatePickerModule,
		CommonDynamicPipeModule,
		SwiperModule,
		UploaderAllModule,
		CodeInputModule,
	],
	providers: [
		LineSeriesService 			  ,
		DateTimeCategoryService       ,
		StripLineService              ,
		LineSeriesService             ,
		DateTimeService               ,
		ColumnSeriesService           ,
		DataLabelService              ,
		ZoomService                   ,
		StackingColumnSeriesService   ,
		CategoryService               ,
		StepAreaSeriesService         ,
		SplineSeriesService           ,
		ChartAnnotationService        ,
		LegendService                 ,
		TooltipService                ,
		StripLineService              ,
		PieSeriesService              ,
		AccumulationTooltipService    ,
		ScrollBarService              ,
		AccumulationDataLabelService  ,
		SelectionService              ,
		ScatterSeriesService          ,
		AreaSeriesService             ,
		RangeAreaSeriesService        ,
		SplineAreaSeriesService       ,
		DateTimeService 			  ,
		ChartGlucoseProvider		  ,
		ChartBloodPressureProviderProvider ,
		CommunityValidatorProvider,
	]
})

/**
 * SharedModule
 */
export class SharedModule {
}
