import { Component, OnInit, Input } from '@angular/core';
import {ChartComponent} from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-syncfusion-chart-no-data',
  templateUrl: './syncfusion-chart-no-data.component.html',
  styleUrls: ['./syncfusion-chart-no-data.component.scss']
})
export class SyncfusionChartNoDataComponent implements OnInit {
	/**
	 * Syncfusion chart 오브젝트 데이터
	 */
	@Input() syncfusionChart:ChartComponent;

	constructor() { }

	ngOnInit(): void {
	}

	/**
	 * 데이터 존재여부
	 */
	get hasData() : boolean {
		let hasData : boolean = false;
		this.syncfusionChart.series.forEach(item => {
			// @ts-ignore
			if(item.recordsCount !== 0)
				hasData = true;
		});
		return hasData;
	}
}
