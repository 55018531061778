import {AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Output, QueryList, Renderer2, ViewChildren} from '@angular/core';
import {TabsHeaderComponent} from './header/tabs-header.component';
import {TabsContentComponent} from './content/tabs-content.component';


@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements AfterViewInit,AfterContentInit {
	/**
	 * 인덱스 속성명
	 * @private
	 */
	private readonly INDEX_ATTRIBUTE_NAME: string = 'itemIndex';
	/**
	 * 현재 선택된 아이템의 인덱스
	 * @private
	 */
	private currentItemIndex:number = 0;
	/**
	 * 헤더그룹
	 */
	@ViewChildren('headerGroups') headerGroups: QueryList<ElementRef>;
	/**
	 * Tab 아이템 References
	 */
	@ContentChildren(TabsHeaderComponent) tabs: QueryList<ElementRef>;
	/**
	 * Tab 아이템 References
	 */
	@ContentChildren(TabsContentComponent) contents: QueryList<ElementRef>;
	/**
	 * 부모로 전달할 이벤트 Emitter
	 */
	@Output() clickTabEvent = new EventEmitter();

	/**
	 * 생성자
	 * @param renderer 렌더러 오브젝트
	 */
	constructor(private renderer: Renderer2) {
	}

	/**
	 *  ngAfterContentInit
	 */
	ngAfterContentInit() {
		const tabArray = this.tabs.toArray();

		tabArray.forEach((tab,i) => {
			setTimeout(() => {
				// @ts-ignore
				tab.inputTagElement.nativeElement.setAttribute(this.INDEX_ATTRIBUTE_NAME,i);
			},100);

			// 첫번째 요소인경우
			if(i === 0) {
				setTimeout(() => {
					// @ts-ignore
					const contentElement = this.contents.toArray()[0].contentElement.nativeElement;
					this.renderer.addClass(contentElement,'display-on');

					// @ts-ignore
					const tabElement = tab.inputTagElement.nativeElement;
					tabElement.checked = true;
				}, 100);
				// tab.inputTag.nativeElement.checked = true;
			}

			// @ts-ignore
			// 탭클릭 이벤트 등록
			tab.clickTabEvent.subscribe((element:ElementRef) => {
				const clickedIndex = parseInt(element.nativeElement.getAttribute(this.INDEX_ATTRIBUTE_NAME),10);

				// 선택한 탭의 Checked 활성화
				element.nativeElement.checked = true;

				// 클릭한 탭과 현재 탭이 같으면 무시
				if(this.currentItemIndex === clickedIndex)
					return;

				// 선택한 아이템으로 변경
				this.currentItemIndex = clickedIndex;

				// 이벤트 Emit
				this.clickTabEvent.emit(clickedIndex);

				const contentArray = this.contents.toArray();

				// 모든 컨텐츠 에 대해 처리한다
				contentArray.forEach((item,itemIndex) => {
					// @ts-ignore
					const content = item.contentElement.nativeElement;

					// 선택한 아이템과 같은 아이템일경우
					if(this.currentItemIndex === itemIndex)
						this.renderer.addClass(content,'display-on');
					// 다를경우
					else
						this.renderer.removeClass(content,'display-on');
				});
			});
		});
	}

	/**
	 *   ngAfterViewInit
	 */
	ngAfterViewInit() {
	}
}
