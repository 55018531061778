/* tslint:disable */
import {Chart, IAxisLabelRenderEventArgs} from '@syncfusion/ej2-angular-charts';
import {Injectable} from '@angular/core';
import {List} from "linq-collections";

@Injectable({
	providedIn: 'root'
})
/**
 * 차트 그리기 마더 클래스
 */
export class ChartBase {
	// 사용 폰트 정보
	public readonly FONT_FAMILY: string = 'Roboto-Regular, Roboto';

	// 차트 번호
	private chartIndex: number = 0;

	/**
	 * 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param title 그래프 타이틀
	 * @param svgBackground SVG 옵션 이미지
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 * @param hasData 데이터 존재 여부
	 */
	drawBaseChart(domIdentity: string
		, title: string
		, svgBackground: string = ''
		, options?: { daysAgo: number; startDate: string , endDate: string}
		, hasData: boolean = true
	) : Chart {
		// 차트가 그려질 root dom 오브젝트
		let rootDom = document.querySelector(`#${domIdentity}`);


		// 기존에 그려진 모든 차트를 초기화 한다
		(rootDom) ?
			rootDom.innerHTML = ''
			: rootDom = new Element();

		// 차트 번호 초기화
		this.chartIndex = 0;

		// dom 에 차트가 그려질 div 를 그린다
		rootDom.innerHTML = rootDom.innerHTML + `<div class=\"health-care-chart\"></div><div>${svgBackground}</div>`;

		// 데이터가 없을 경우
		if(!hasData)
			rootDom.innerHTML += '<div class=\"graph-no-data\">데이터가 없습니다</div>';
		else
			rootDom.innerHTML += '<div class=\"graph-no-data\">데이터를 로딩중입니다</div>';

		// 차트 Dom 을 생성한다
		return this.createChartInstance(title,domIdentity,hasData, '.graph-no-data');
	}

	/**
	 * 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param title 그래프 타이틀
	 * @param svgBackground SVG 옵션 이미지
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 * @param hasData 데이터 존재 여부
	 */
	drawBaseChartWithNoMessage(domIdentity: string
		, title: string
		, svgBackground: string = ''
		, options?: { daysAgo: number; startDate: string , endDate: string}
		, hasData: boolean = true
	) : Chart {
		// 차트가 그려질 root dom 오브젝트
		let rootDom = document.querySelector(`#${domIdentity}`);

		// 기존에 그려진 모든 차트를 초기화 한다
		(rootDom) ? rootDom.innerHTML = '' : rootDom = new Element();

		// 차트 번호 초기화
		this.chartIndex = 0;

		// dom 에 차트가 그려질 div 를 그린다
		rootDom.innerHTML = rootDom.innerHTML + `<div class=\"health-care-chart\"></div><div>${svgBackground}</div>`;

		// 차트 Dom 을 생성한다
		return this.createChartInstance(title,domIdentity,hasData, '.graph-no-data');
	}


	/**
	 * 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param title 그래프 타이틀
	 * @param svgBackground SVG 옵션 이미지
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 * @param hasData 데이터 존재 여부
	 */
	drawBaseChartDynamic(domIdentity: string
		, title: string
		, svgBackground: string = ''
		, options?: { daysAgo: number; startDate: string , endDate: string}
		, hasData: boolean = true
	) : Chart {
		// 차트가 그려질 root dom 오브젝트
		let rootDom = document.querySelector(`#${domIdentity}`);

		// 기존에 그려진 모든 차트를 초기화 한다
		(rootDom) ? rootDom.innerHTML = '' : rootDom = new Element();

		// 차트 번호 초기화
		this.chartIndex = 0;

		// dom 에 차트가 그려질 div 를 그린다
		rootDom.innerHTML = rootDom.innerHTML + `<div class=\"health-care-chart\"></div><div>${svgBackground}</div>`;

		// 데이터가 없을 경우
		if(!hasData)
			rootDom.innerHTML += '<div class=\"graph-no-data-dynamic\">데이터가 없습니다</div>';
		else
			rootDom.innerHTML += '<div class=\"graph-no-data-dynamic\">데이터를 로딩중입니다</div>';

		// 차트 Dom 을 생성한다
		return this.createChartInstance(title,domIdentity,hasData,'.graph-no-data-dynamic');
	}


	/**
	 * 차트를 리턴한다
	 * @param title 차트 타이틀
	 * @param domIdentity dom 구분자
	 * @param hasData 데이터 존재 여부
	 * @param plateClass 플레이트 클래스
	 */
	createChartInstance(title:string, domIdentity: string ,hasData: boolean = true, plateClass: string = '') {
		const chart = new Chart({
			axisLabelRender : (args:IAxisLabelRenderEventArgs) => {
				const name = args.axis.name;

				// Y 축 라벨인 경우
				if(name === 'primaryYAxis') {
					// 소수점 고정
					args.text =	args.value.toFixed(0).toSeparateComma();
				}

				// X 축 라벨인 경우
				if(name === 'primaryXAxis') {
					args.text = new Date(args.value).format('MM/dd');
				}
			} ,
			title ,
			border: {
				width: 0 ,
			},
			locale: 'ko',
			width : '97%',
			height: '400',
			primaryXAxis: {
				  visible: true
				, valueType: 'DateTime'
				, labelPadding : 10
				, minorTickLines : { width : 0 }
				, majorTickLines : { width : 0 }
				, minorGridLines : { width : 0 }
				, majorGridLines : { width : 0 }
				, labelStyle 	 : {
					size 	   : '10',
					fontFamily : this.FONT_FAMILY,
				}
				, edgeLabelPlacement: 'Shift'
				, lineStyle : {
					width : 0
				}
			},
			primaryYAxis: {
				  visible: true
				, labelPadding : 10
				, lineStyle : { width: 0 }
				, minorTickLines : { width : 0 }
				, majorTickLines : { width : 0 }
				, minorGridLines : { width : 0 }
				, majorGridLines : { width : 0 }
				, labelStyle 	 : {
					size 	   : '10',
					fontFamily : this.FONT_FAMILY,
				}
			},
			titleStyle: {
				textAlignment 	: 'Near',
				fontFamily 		: this.FONT_FAMILY,
				size 			: '14',
				textOverflow 	: 'Wrap',
				fontWeight		: 'bold',
			},
			subTitle: ' ',
			subTitleStyle: {
				textAlignment 	: 'Near',
				fontFamily 		: this.FONT_FAMILY,
				size 			: '12',
				textOverflow 	: 'None',
			},
			tooltip: {
				enable			: true ,
				header			: '' ,
				enableMarker	: false ,
				fill			: 'white',
				textStyle: {
					fontFamily	: this.FONT_FAMILY ,
					color		: 'black',
				} ,
				opacity: 0.5
			},
			legendSettings : {
				textStyle : {
					size 	   : '12',
					fontFamily : this.FONT_FAMILY,
				},
				padding: 20 ,
			},
			zoomSettings : (hasData) ? {
				enableMouseWheelZooming		: false,
				enablePinchZooming			: true,
				enableSelectionZooming		: true,
				enableScrollbar				: true
			} : {},
			chartArea : {
				border : {
					width:0 ,
					color:'white'
				}
			},
		}, `#${domIdentity}`);
		if(hasData) {
			const noDataDom = document.querySelector(`#${domIdentity} ${plateClass}`);

			chart.loaded = () => {
				if(noDataDom)
					noDataDom.innerHTML = '';
			}
		}
		return chart;
	}
	// endregion

	/**
	 * Y 엑시스 레인지를 설정한다
	 * @param chartObject
	 * @param listData
	 */
	setYAxesRange(chartObject:Chart,listData:any[]) {
		const list = new List(listData.map(i => i['y']));
		if(list.count() > 0) {
			chartObject.primaryYAxis.minimum = list.min();
			chartObject.primaryYAxis.maximum = list.max();
		}
	}

}


