/**
 * 인증 번호 요청 정보 클래스
 */
export class RequestPhoneVerifyCode {
	// 전화번호
	public PhoneNumber: string;

	/**
	 * 생성자
	 * @param phoneNumber 핸드폰 번호
	 */
	constructor(phoneNumber: string) {
		this.PhoneNumber = phoneNumber;
	}
}


