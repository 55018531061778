import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 * Document 이벤트 서비스 클래스
 */
@Injectable({
	providedIn: 'root'
})
export class DocumentBehaviorService {
	// document 소스 객체
	documentSource: any = {};

	// document Behavior 객체
	documentSubject = new BehaviorSubject(this.documentSource);

	// document Observable (구독) 객체
	documentObservable = this.documentSubject.asObservable();

	/**
	 * 외부 클릭 이벤트
	 * @param event dom 이벤트 객체
	 */
	nextClickOutside(event:any) {
		this.documentSubject.next(event);
	}
}
