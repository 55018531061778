import {Injectable} from '@angular/core';
import {ChartBase} from './chart-base';
import {
	Category,
	Chart,
	DateTime,
	DateTimeCategory,
	IAccTooltipRenderEventArgs,
	Legend,
	StripLine,
	Tooltip,
	Zoom,
	IAxisLabelRenderEventArgs,
	DataLabel,
	ScatterSeries,
	SplineSeries,
	SplineAreaSeries, RangeAreaSeries
} from '@syncfusion/ej2-angular-charts';
import {ResponseBloodPressureData} from '../../../models/response/data/response-blood-pressure-data.model';
import {IList, List} from 'linq-collections';
import {EnumDataInputMethod} from '../../../models/enums/enum-data-input-method.model';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})

/**
 * 혈당 차트를 그린다
 */
export class ChartBloodPressureProviderProvider extends ChartBase {
	// 최근 혈압 차트 파싱 데이터
	private bloodPressureDataRecent = new BloodPressureDataRecent();

	// 최근 맥박 차트 파싱 데이터
	private pulseDataRecent = new PulseData();

	// 날짜별 맥박 차트 파싱 데이터
	private bloodPressureDataByDate = new BloodPressureDataByDate();

	// 시간대별 맥박 차트 파싱 데이터
	private bloodPressureDataByTime = new BloodPressureDataByDate();

	// 시간대별 맥박 차트 파싱 데이터
	private pulseDataByDate = new PulseData();

	/**
	 * 생성자
	 */
	constructor() {
		super();
		Chart.Inject(RangeAreaSeries, SplineSeries, SplineAreaSeries , ScatterSeries, Category, Tooltip, Legend , DateTime ,DateTimeCategory, Zoom, StripLine, DataLabel);
	}

	/**
	 * 최근 시간대별 혈압계 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param parsedData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawBloodPressureByTimeRecent(domIdentity: string, parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parseBloodPressureByTimeRecent(parsedData);

		// 데이터 존재 여부
		const hasData: boolean = parsedData.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, '' ,options, hasData);

		// 차트 디테일 설정
		chartObject.primaryYAxis.majorGridLines.width = 1;
		// chartObject.legendSettings.width = '300px';
		chartObject.series = [
			{
				name: '수축기 혈압(측정)',
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#FF8C76',
				dataSource:this.bloodPressureDataRecent.updatedSystolic
			},
			// {
			// 	name: '평균 혈압(측정)',
			// 	type: 'Scatter',
			// 	xName: 'x' ,
			// 	yName: 'y' ,
			// 	fill: '#90ED7D',
			// 	dataSource:this.bloodPressureDataRecent.updatedMean
			// },
			{
				name: '이완기 혈압(측정)',
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#F7A35C',
				dataSource:this.bloodPressureDataRecent.updatedDiastolic
			},
			{
				name: '수축기 혈압(수기)',
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#8084E8',
				dataSource:this.bloodPressureDataRecent.updatedSystolicManual
			},
			// {
			// 	name: '평균 혈압(수기)',
			// 	type: 'Scatter',
			// 	xName: 'x' ,
			// 	yName: 'y' ,
			// 	fill: '#8EFFFF',
			// 	dataSource:this.bloodPressureDataRecent.updatedMeanManual
			// },
			{
				name: '이완기 혈압(수기)',
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#7CB5EB',
				dataSource:this.bloodPressureDataRecent.updatedDiastolicManual
			}
		];
		let newStartDate: string = '';
		const list = new List(parsedData.map(i => moment(i.Date).valueOf()));
		if(list.count() > 0) {
			chartObject.primaryXAxis.minimum = moment(list.min()).subtract(30,'minutes');
			chartObject.primaryXAxis.maximum = moment(list.max()).add(30,'minutes');
		}
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			const name = event.axis.name;

			// Y 축 라벨인 경우
			if(name === 'primaryYAxis')
				event.text = `${event.text} mmHg`;

			// X 축 라벨인 경우
			if(name === 'primaryXAxis') {
				const checkDate = new Date(event.value).format('MM/dd');

				// 날짜가 바뀐경우
				if(checkDate !== newStartDate || event.text === event.axis.startLabel) {
					event.text = checkDate;
					newStartDate = checkDate;
				}
				// 바뀌지 않은 경우
				else {
					event.text = new Date(event.value).format('HH:mm');
				}
			}
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			const yData = event.point.y;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일 hh:mm');
			textContent += `<br>`;
			textContent += `<span>${event.series.name} : ${yData.toFixed(1).toSeparateComma()} mmHg</span>`;

			event.text = textContent;
		};
	}

	/**
	 * 최근 시간대별 맥박박차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param parsedData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawBloodPulsepressureByTimeRecent(domIdentity: string, parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parsePulseByTimeRecent(parsedData);

		// 데이터 존재 여부
		const hasData: boolean = parsedData.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, '' ,options, hasData);



		// 차트 디테일 설정
		chartObject.primaryYAxis.majorGridLines.width = 1;
		chartObject.series = [
			{
				name: '맥박(측정)',
				type: 'SplineArea',
				xName: 'x' ,
				yName: 'y' ,
				opacity: 0.5 ,
				dataSource: this.pulseDataRecent.updatedRate ,
				fill: '#f0aa58' ,
				marker: {
					width: 10 ,
					height: 10 ,
					visible: true ,
				}
			},
			{
				name: '맥박(수기)',
				type: 'SplineArea',
				xName: 'x' ,
				yName: 'y' ,
				opacity: 0.5 ,
				dataSource: this.pulseDataRecent.updatedRateManual ,
				fill: '#e04c58' ,
				marker: {
					width: 10 ,
					height: 10 ,
					visible: true ,
				}
			},
		];
		let newStartDate: string = '';
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			const name = event.axis.name;

			// Y 축 라벨인 경우
			if(name === 'primaryYAxis')
				event.text = `${event.value.toFixed(0)} 회/분`;

			// X 축 라벨인 경우
			if(name === 'primaryXAxis') {
				const checkDate = new Date(event.value).format('MM/dd');

				// 날짜가 바뀐경우
				if(checkDate !== newStartDate || event.text === event.axis.startLabel) {
					event.text = checkDate;
					newStartDate = checkDate;
				}
				// 바뀌지 않은 경우
				else {
					event.text = new Date(event.value).format('HH:mm');
				}
			}
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			const yData = event.point.y;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일 hh:mm');
			textContent += `<br>`;
			textContent += `<span>${event.series.name} : ${yData.toFixed(1).toSeparateComma()} 회/분</span>`;

			event.text = textContent;
		};
	}

	/**
	 * 날짜별 혈압계 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param parsedData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawBloodPressureByDate(domIdentity: string, parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parseBloodPressureByDate(parsedData);

		// 데이터 존재 여부
		const hasData: boolean = parsedData.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, '' ,options, hasData);
		// Scatter 를 위한 데이터 처리
		const updatedSystolicParsed: [] = [];

		// 수축기 정보
		this.bloodPressureDataByDate.updatedSystolic.forEach((i: { x: any; low: any; high: any; }) => {
			updatedSystolicParsed.push({
				// @ts-ignore
				x: i.x  ,
				// @ts-ignore
				y: i.low
			});

			updatedSystolicParsed.push({
				// @ts-ignore
				x: i.x  ,
				// @ts-ignore
				y: i.high
			});
		});

		// Scatter 를 위한 데이터 처리
		const updatedDiastolicParsed:[] = [];

		console.log('this.bloodPressureDataByDate.updatedDiastolic',this.bloodPressureDataByDate.updatedDiastolic);

		// 이완기 정보
		this.bloodPressureDataByDate.updatedDiastolic.forEach((i: { x: any; low: any; high: any; }) => {
			updatedDiastolicParsed.push({
				// @ts-ignore
				x: i.x  ,
				// @ts-ignore
				y: i.low
			});

			updatedDiastolicParsed.push({
				// @ts-ignore
				x: i.x  ,
				// @ts-ignore
				y: i.high
			});
		});

		// 차트 디테일 설정
		chartObject.primaryYAxis.majorGridLines.width = 1;
		chartObject.series = [
			{
				name: '수축기 최대/최소(측정)',
				opacity:0.8,
				type: 'Scatter',
				xName: 'x' ,
				dataSource: updatedSystolicParsed,
				yName:'y' ,
				fill: '#faada2',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#faada2' ,
				},
				legendShape:'Circle'
			},
			{
				name: '수축기 평균(측정)'	,
				type: 'Line',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByDate.updatedSystolicAverage,
				yName: 'y' ,
				fill: '#FF8C76',
				marker: {
					visible: true ,
					width: 5,
					height: 5,
					fill:'#FF8C76' ,
					border:{
						color:'#FF8C76'
					}
				},
			},
			// {
			// 	name: '평균 혈압 최대/최소(측정)',
			// 	opacity:0.8,
			// 	type: 'RangeArea',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByDate.updatedMean,
			// 	high:'high' ,
			// 	low:'low' ,
			// 	border:{
			// 		width:2,
			// 		color:'#90ED7D'
			// 	},
			// 	fill: '#E9FCE5',
			// 	marker: {
			// 		visible: true ,
			// 		width: 7,
			// 		height: 7,
			// 		fill:'#90ED7D' ,
			// 		border:{
			// 			color:'#90ED7D'
			// 		}
			// 	},
			// 	legendShape:'Circle'
			// },
			// {
			// 	name: '평균 혈압(측정)'		,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByDate.updatedMeanAverage,
			// 	yName: 'y' ,
			// 	fill: '#90ED7D',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#90ED7D' ,
			// 		border:{
			// 			color:'#90ED7D'
			// 		}
			// 	},
			// },
			{
				name: '이완기 최대/최소(측정)',
				opacity:0.8,
				type: 'Scatter',
				xName: 'x' ,
				dataSource: updatedDiastolicParsed,
				yName:'y' ,
				low:'low' ,
				fill: '#778ada',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#778ada' ,
				},
				legendShape:'Circle'
			},
			{
				name: '이완기 평균(측정)',
				type: 'Line',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByDate.updatedDiastolicAverage,
				yName: 'y' ,
				fill: '#2342de',
				marker: {
					visible: true ,
					width: 5,
					height: 5,
					fill:'#2342de' ,
					border:{
						color:'#2342de'
					}
				},
			},
			{
				name: '수축기 최대/최소(수기)',
				opacity:0.8,
				type: 'RangeArea',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByDate.updatedSystolicManual,
				high:'high' ,
				low:'low' ,
				border:{
					width:2,
					color:'#8084E8'
				},
				fill: '#bfc2e0',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#8084E8' ,
					border:{
						color:'#8084E8'
					}
				},
				legendShape:'Circle'
			},
			// {
			// 	name: '수축기 평균(수기)'					,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByDate.updatedSystolicAverageManual,
			// 	yName: 'y' ,
			// 	fill: '#8084E8',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#8084E8' ,
			// 		border:{
			// 			color:'#8084E8'
			// 		}
			// 	},
			// },
			// {
			// 	name: '평균 혈압 최대/최소(수기)',
			// 	opacity:0.8,
			// 	type: 'RangeArea',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByDate.updatedMeanManual,
			// 	high:'high' ,
			// 	low:'low' ,
			// 	border:{
			// 		width:2,
			// 		color:'#8EFFFF'
			// 	},
			// 	fill: '#c7f8f8',
			// 	marker: {
			// 		visible: true ,
			// 		width: 7,
			// 		height: 7,
			// 		fill:'#8EFFFF' ,
			// 		border:{
			// 			color:'#8EFFFF'
			// 		}
			// 	},
			// 	legendShape:'Circle'
			// },
			// {
			// 	name: '평균 혈압(수기)'					,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByDate.updatedMeanAverageManual,
			// 	yName: 'y' ,
			// 	fill: '#8EFFFF',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#8EFFFF' ,
			// 		border:{
			// 			color:'#8EFFFF'
			// 		}
			// 	},
			// },
			{
				name: '이완기 최대/최소(수기)',
				opacity:0.8,
				type: 'RangeArea',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByDate.updatedDiastolicManual,
				high:'high' ,
				low:'low' ,
				border:{
					width:2,
					color:'#7CB5EB'
				},
				fill: '#c6d4e1',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#7CB5EB' ,
					border:{
						color:'#7CB5EB'
					}
				},
				legendShape:'Circle'
			},
			// {
			// 	name: '이완기 평균(수기)'					,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByDate.updatedDiastolicAverageManual,
			// 	yName: 'y' ,
			// 	fill: '#7CB5EB',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#7CB5EB' ,
			// 		border:{
			// 			color:'#7CB5EB'
			// 		}
			// 	},
			// },
		];

		const list = new List(parsedData.map(i => moment(i.Date).valueOf()));
		if(list.count() > 0) {
			chartObject.primaryXAxis.minimum = moment(list.min()).subtract(1,'days');
			chartObject.primaryXAxis.maximum = moment(list.max()).add(1,'days');
		}
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			const name = event.axis.name;

			// Y 축 라벨인 경우
			if(name === 'primaryYAxis')
				event.text = `${event.text} mmHg`;

			// X 축 라벨인 경우
			if(name === 'primaryXAxis') {
				event.text = new Date(event.value).format('MM/dd');
			}
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			// @ts-ignore
			// tslint:disable-next-line:radix
			const yData = (event.point.y !== undefined) ? parseInt(event.point.y.toString()) : 0;


			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일 hh:mm');
			textContent += `<br>`;
			textContent += `<span>${event.series.name} : ${yData.toFixed(1).toSeparateComma()} mmHg</span>`;

			event.text = textContent;
		};
	}

	/**
	 * 시간대별 혈압계 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param parsedData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawBloodPressureByTime(domIdentity: string, parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parseBloodPressureByTime(parsedData);

		// 데이터 존재 여부
		const hasData: boolean = parsedData.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, '' ,options, hasData);

		// 차트 디테일 설정
		chartObject.primaryYAxis.majorGridLines.width = 1;
		chartObject.series = [
			{
				name: '수축기 최대/최소(측정)',
				opacity:0.8,
				type: 'RangeArea',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByTime.updatedSystolic,
				high:'high' ,
				low:'low' ,
				border:{
					width:2,
					color:'#fa3a17'
				},
				fill: '#fa3a17',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#fa3a17' ,
					border:{ color:'#fa3a17'}
				},
				legendShape:'Circle'
			},
			// {
			// 	name: '수축기 평균(측정)'	,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedSystolicAverage,
			// 	yName: 'y' ,
			// 	fill: '#FF8C76',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#FF8C76' ,
			// 		border:{
			// 			color:'#FF8C76'
			// 		}
			// 	},
			// },
			// {
			// 	name: '평균 혈압 최대/최소(측정)',
			// 	opacity:0.8,
			// 	type: 'RangeArea',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedMean,
			// 	high:'high' ,
			// 	low:'low' ,
			// 	border:{
			// 		width:2,
			// 		color:'#90ED7D'
			// 	},
			// 	fill: '#E9FCE5',
			// 	marker: {
			// 		visible: true ,
			// 		width: 7,
			// 		height: 7,
			// 		fill:'#90ED7D' ,
			// 		border:{
			// 			color:'#90ED7D'
			// 		}
			// 	},
			// 	legendShape:'Circle'
			// },
			// {
			// 	name: '평균 혈압(측정)'		,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedMeanAverage,
			// 	yName: 'y' ,
			// 	fill: '#90ED7D',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#90ED7D' ,
			// 		border:{
			// 			color:'#90ED7D'
			// 		}
			// 	},
			// },
			{
				name: '이완기 최대/최소(측정)',
				opacity:0.8,
				type: 'RangeArea',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByTime.updatedDiastolic,
				high:'high' ,
				low:'low' ,
				border:{
					width:2,
					color:'#5c76f7'
				},
				fill: '#5c76f7',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#5c76f7' ,
					border:{
						color:'#5c76f7'
					}
				},
				legendShape:'Circle'
			},
			// {
			// 	name: '이완기 평균(측정)',
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedDiastolicAverage,
			// 	yName: 'y' ,
			// 	fill: '#F7A35C',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#F7A35C' ,
			// 		border:{
			// 			color:'#F7A35C'
			// 		}
			// 	},
			// },
			{
				name: '수축기 최대/최소(수기)',
				opacity:0.8,
				type: 'RangeArea',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByTime.updatedSystolicManual,
				high:'high' ,
				low:'low' ,
				border:{
					width:2,
					color:'#8084E8'
				},
				fill: '#bfc2e0',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#8084E8' ,
					border:{
						color:'#8084E8'
					}
				},
				legendShape:'Circle'
			},
			// {
			// 	name: '수축기 평균(수기)'					,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedSystolicAverageManual,
			// 	yName: 'y' ,
			// 	fill: '#8084E8',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#8084E8' ,
			// 		border:{
			// 			color:'#8084E8'
			// 		}
			// 	},
			// },
			// {
			// 	name: '평균 혈압 최대/최소(수기)',
			// 	opacity:0.8,
			// 	type: 'RangeArea',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedMeanManual,
			// 	high:'high' ,
			// 	low:'low' ,
			// 	border:{
			// 		width:2,
			// 		color:'#8EFFFF'
			// 	},
			// 	fill: '#c7f8f8',
			// 	marker: {
			// 		visible: true ,
			// 		width: 7,
			// 		height: 7,
			// 		fill:'#8EFFFF' ,
			// 		border:{
			// 			color:'#8EFFFF'
			// 		}
			// 	},
			// 	legendShape:'Circle'
			// },
			// {
			// 	name: '평균 혈압(수기)'					,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedMeanAverageManual,
			// 	yName: 'y' ,
			// 	fill: '#8EFFFF',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#8EFFFF' ,
			// 		border:{
			// 			color:'#8EFFFF'
			// 		}
			// 	},
			// },
			{
				name: '이완기 최대/최소(수기)',
				opacity:0.8,
				type: 'RangeArea',
				xName: 'x' ,
				dataSource: this.bloodPressureDataByTime.updatedDiastolicManual,
				high:'high' ,
				low:'low' ,
				border:{
					width:2,
					color:'#7CB5EB'
				},
				fill: '#c6d4e1',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#7CB5EB' ,
					border:{
						color:'#7CB5EB'
					}
				},
				legendShape:'Circle'
			},
			// {
			// 	name: '이완기 평균(수기)'					,
			// 	type: 'Line',
			// 	xName: 'x' ,
			// 	dataSource: this.bloodPressureDataByTime.updatedDiastolicAverageManual,
			// 	yName: 'y' ,
			// 	fill: '#7CB5EB',
			// 	marker: {
			// 		visible: true ,
			// 		width: 5,
			// 		height: 5,
			// 		fill:'#7CB5EB' ,
			// 		border:{
			// 			color:'#7CB5EB'
			// 		}
			// 	},
			// },
		];

		const list = new List(parsedData.map(i => moment(i.Date).valueOf()));
		if(list.count() > 0) {
			chartObject.primaryXAxis.minimum = moment(list.min()).subtract(30,'minutes');
			chartObject.primaryXAxis.maximum = moment(list.max()).add(30,'minutes');
		}
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			const name = event.axis.name;

			// Y 축 라벨인 경우
			if(name === 'primaryYAxis')
				event.text = `${event.text} mmHg`;

			// X 축 라벨인 경우
			if(name === 'primaryXAxis')
				event.text = new Date(event.value).format('HH:mm');
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			// @ts-ignore
			const yData = (event.point.high !== undefined) ? event.point.high : 0;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일 hh:mm');
			textContent += `<br>`;
			textContent += `<span>${event.series.name} : ${yData.toFixed(1).toSeparateComma()} mmHg</span>`;

			event.text = textContent;
		};
	}


	/**
	 * 날짜별 맥박 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param parsedData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawBloodPulsePressureByDate(domIdentity: string, parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parsePulseByTimeByDate(parsedData);

		// 데이터 존재 여부
		const hasData: boolean = parsedData.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, '' ,options, hasData);

		const updatedRateParse:[] = [] ;
		this.pulseDataByDate.updatedRate.forEach((i: { x: any; low: any; high: any; }) => {
			// @ts-ignore
			updatedRateParse.push({x: i.x  , y: i.low});
			// @ts-ignore
			updatedRateParse.push({x: i.x  , y: i.high});
		});

		const updatedRateManualParse:[] = [] ;
		this.pulseDataByDate.updatedRateManual.forEach((i: { x: any; low: any; high: any; }) => {
			// @ts-ignore
			updatedRateManualParse.push({x: i.x  , y: i.low});
			// @ts-ignore
			updatedRateManualParse.push({x: i.x  , y: i.high});
		});



		// 차트 디테일 설정
		chartObject.primaryYAxis.majorGridLines.width = 1;
		chartObject.series = [
			{
				name: '맥박 최대/최소(측정)',
				opacity:0.8,
				type: 'Scatter',
				xName: 'x' ,
				dataSource: updatedRateParse,
				yName:'y' ,
				low:'low' ,
				fill: '#acfa9c',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#acfa9c' ,
				},
				legendShape:'Circle'
			},
			{
				name: '평균 맥박(측정)'		,
				type: 'Line',
				xName: 'x' ,
				dataSource: this.pulseDataByDate.updatedAverage,
				yName: 'y' ,
				fill: '#219807',
				marker: {
					visible: true ,
					width: 5,
					height: 5,
					fill:'#219807' ,
				},
			},

			{
				name: '맥박 최대/최소(수기)',
				opacity:0.8,
				type: 'Scatter',
				xName: 'x' ,
				dataSource: updatedRateManualParse,
				yName:'y' ,
				low:'low' ,
				fill: '#a8b3f5',
				marker: {
					visible: true ,
					width: 7,
					height: 7,
					fill:'#a8b3f5' ,
				},
				legendShape:'Circle'
			},
			{
				name: '평균 맥박(수기)'					,
				type: 'Line',
				xName: 'x' ,
				dataSource: this.pulseDataByDate.updatedAverageManual,
				yName: 'y' ,
				fill: '#4b62ee',
				marker: {
					visible: true ,
					width: 5,
					height: 5,
					fill:'#4b62ee' ,
				},
			},
		];

		const list = new List(parsedData.map(i => moment(i.Date).valueOf()));
		if(list.count() > 0) {
			chartObject.primaryXAxis.minimum = moment(list.min()).subtract(30,'minutes');
			chartObject.primaryXAxis.maximum = moment(list.max()).add(30,'minutes');
		}
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			const name = event.axis.name;

			// Y 축 라벨인 경우
			if(name === 'primaryYAxis')
				event.text = `${event.text} 회/분`;

			// X 축 라벨인 경우
			if(name === 'primaryXAxis')
				event.text = new Date(event.value).format('MM/dd');
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			// @ts-ignore
			const yData = (event.point.y !== undefined) ? event.point.y : 0;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일 hh:mm');
			textContent += `<br>`;
			textContent += `<span>${event.series.name} : ${yData.toFixed(1).toSeparateComma()} 회/분</span>`;

			event.text = textContent;
		};
	}
	// endregion

	/**
	 * 데이터를 파싱한다
	 * @param parsedData 파싱할 데이터
	 * @private
	 */
	private parseBloodPressureByTimeRecent(parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]) {
		this.bloodPressureDataRecent = new BloodPressureDataRecent();

		parsedData.forEach((data: { Date: string; Values: ResponseBloodPressureData[] }) => {
			const thisTime = new Date(data.Date).getTime();
			const valueForDeviceList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Device)
				.toList();
			const valueForManualList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Manual)
				.toList();

			// 측정 데이터가 존재하는 경우
			if(valueForDeviceList.count() > 0) {
				const tempDiastolic = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Diastolic) * 10) / 10
				};
				const tempSystolic = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Systolic) * 10) / 10
				};
				const tempMean = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Mean) * 10) / 10
				};

				this.bloodPressureDataRecent.updatedDiastolic.push(tempDiastolic);
				this.bloodPressureDataRecent.updatedSystolic.push(tempSystolic);
				this.bloodPressureDataRecent.updatedMean.push(tempMean);
			}

			// 수기 데이터가 존재하는 경우
			if(valueForManualList.count() > 0) {
				const tempDiastolic = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Diastolic) * 10) / 10
				};
				const tempSystolic = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Systolic) * 10) / 10
				};
				const tempMean = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Mean) * 10) / 10
				};

				this.bloodPressureDataRecent.updatedDiastolicManual.push(tempDiastolic);
				this.bloodPressureDataRecent.updatedSystolicManual.push(tempSystolic);
				this.bloodPressureDataRecent.updatedMeanManual.push(tempMean);
			}
		});
	}

	/**
	 * 데이터를 파싱한다
	 * @param parsedData 파싱할 데이터
	 * @private
	 */
	private parsePulseByTimeRecent(parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]) {
		this.pulseDataRecent = new PulseData();

		parsedData.forEach((data: { Date: string; Values: ResponseBloodPressureData[] }) => {
			const thisTime = new Date(data.Date).getTime();
			const valueForDeviceList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Device)
				.toList();
			const valueForManualList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Manual)
				.toList();

			// 측정 데이터가 존재하는 경우
			if(valueForDeviceList.count() > 0) {
				const tempRate = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Rate) * 10) / 10
				};
				this.pulseDataRecent.updatedRate.push(tempRate);
			}
			// 수기 데이터가 존재하는 경우
			if(valueForManualList.count() > 0) {
				const tempRate = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Rate) * 10) / 10
				};
				this.pulseDataRecent.updatedRateManual.push(tempRate);
			}
		});
	}

	/**
	 * 데이터를 파싱한다
	 * @param parsedData 파싱할 데이터
	 * @private
	 */
	private parseBloodPressureByDate(parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]) {
		this.bloodPressureDataByDate = new BloodPressureDataByDate();



		parsedData.forEach((data: { Date: string; Values: ResponseBloodPressureData[] }) => {
			const thisTime = new Date(data.Date).getTime();
			const valueForDeviceList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Device)
				.toList();
			const valueForManualList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Manual)
				.toList();

			// 측정 데이터가 존재하는 경우
			if(valueForDeviceList.count() > 0) {
				const tempDiastolic = {
					x:thisTime, low:valueForDeviceList.min(i => i.Diastolic), high:valueForDeviceList.max(i => i.Diastolic)
				};
				const tempDiastolicAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Diastolic) * 10) / 10
				};
				const tempSystolic = {
					x:thisTime, low:valueForDeviceList.min(i => i.Systolic), high:valueForDeviceList.max(i => i.Systolic)
				};
				const tempSystolicAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Systolic) * 10) / 10
				};
				const tempMean = {
					x:thisTime, low:valueForDeviceList.min(i => i.Mean), high:valueForDeviceList.max(i => i.Mean)
				};
				const tempMeanAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Mean) * 10) / 10
				};

				this.bloodPressureDataByDate.updatedDiastolic.push(tempDiastolic);
				this.bloodPressureDataByDate.updatedDiastolicAverage.push(tempDiastolicAverage);
				this.bloodPressureDataByDate.updatedSystolic.push(tempSystolic);
				this.bloodPressureDataByDate.updatedSystolicAverage.push(tempSystolicAverage);
				this.bloodPressureDataByDate.updatedMean.push(tempMean);
				this.bloodPressureDataByDate.updatedMeanAverage.push(tempMeanAverage);
			}

			// 수기 데이터가 존재하는 경우
			if(valueForManualList.count() > 0) {
				const tempDiastolic = {
					x:thisTime, low:valueForManualList.min(i => i.Diastolic), high:valueForManualList.max(i => i.Diastolic)
				};
				const tempDiastolicAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Diastolic) * 10) / 10
				};
				const tempSystolic = {
					x:thisTime, low:valueForManualList.min(i => i.Systolic), high:valueForManualList.max(i => i.Systolic)
				};
				const tempSystolicAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Systolic) * 10) / 10
				};
				const tempMean = {
					x:thisTime, low:valueForManualList.min(i => i.Mean), high:valueForManualList.max(i => i.Mean)
				};
				const tempMeanAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Mean) * 10) / 10
				};

				this.bloodPressureDataByDate.updatedDiastolicManual.push(tempDiastolic);
				this.bloodPressureDataByDate.updatedDiastolicAverageManual.push(tempDiastolicAverage);
				this.bloodPressureDataByDate.updatedSystolicManual.push(tempSystolic);
				this.bloodPressureDataByDate.updatedSystolicAverageManual.push(tempSystolicAverage);
				this.bloodPressureDataByDate.updatedMeanManual.push(tempMean);
				this.bloodPressureDataByDate.updatedMeanAverageManual.push(tempMeanAverage);
			}
		});
	}

	/**
	 * 데이터를 파싱한다
	 * @param parsedData 파싱할 데이터
	 * @private
	 */
	private parseBloodPressureByTime(parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]) {
		this.bloodPressureDataByTime = new BloodPressureDataByDate();

		parsedData.forEach((data: { Date: string; Values: ResponseBloodPressureData[] }) => {
			const thisTime = new Date(data.Date).getTime();
			const valueForDeviceList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Device)
				.toList();
			const valueForManualList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Manual)
				.toList();

			// 측정 데이터가 존재하는 경우
			if(valueForDeviceList.count() > 0) {
				const tempDiastolic = {
					x:thisTime, low:valueForDeviceList.min(i => i.Diastolic), high:valueForDeviceList.max(i => i.Diastolic)
				};
				const tempDiastolicAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Diastolic) * 10) / 10
				};
				const tempSystolic = {
					x:thisTime, low:valueForDeviceList.min(i => i.Systolic), high:valueForDeviceList.max(i => i.Systolic)
				};
				const tempSystolicAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Systolic) * 10) / 10
				};
				const tempMean = {
					x:thisTime, low:valueForDeviceList.min(i => i.Mean), high:valueForDeviceList.max(i => i.Mean)
				};
				const tempMeanAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Mean) * 10) / 10
				};

				this.bloodPressureDataByTime.updatedDiastolic.push(tempDiastolic);
				this.bloodPressureDataByTime.updatedDiastolicAverage.push(tempDiastolicAverage);
				this.bloodPressureDataByTime.updatedSystolic.push(tempSystolic);
				this.bloodPressureDataByTime.updatedSystolicAverage.push(tempSystolicAverage);
				this.bloodPressureDataByTime.updatedMean.push(tempMean);
				this.bloodPressureDataByTime.updatedMeanAverage.push(tempMeanAverage);
			}

			// 수기 데이터가 존재하는 경우
			if(valueForManualList.count() > 0) {
				const tempDiastolic = {
					x:thisTime, low:valueForManualList.min(i => i.Diastolic), high:valueForManualList.max(i => i.Diastolic)
				};
				const tempDiastolicAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Diastolic) * 10) / 10
				};
				const tempSystolic = {
					x:thisTime, low:valueForManualList.min(i => i.Systolic), high:valueForManualList.max(i => i.Systolic)
				};
				const tempSystolicAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Systolic) * 10) / 10
				};
				const tempMean = {
					x:thisTime, low:valueForManualList.min(i => i.Mean), high:valueForManualList.max(i => i.Mean)
				};
				const tempMeanAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Mean) * 10) / 10
				};

				this.bloodPressureDataByTime.updatedDiastolicManual.push(tempDiastolic);
				this.bloodPressureDataByTime.updatedDiastolicAverageManual.push(tempDiastolicAverage);
				this.bloodPressureDataByTime.updatedSystolicManual.push(tempSystolic);
				this.bloodPressureDataByTime.updatedSystolicAverageManual.push(tempSystolicAverage);
				this.bloodPressureDataByTime.updatedMeanManual.push(tempMean);
				this.bloodPressureDataByTime.updatedMeanAverageManual.push(tempMeanAverage);
			}
		});
	}

	/**
	 * 데이터를 파싱한다
	 * @param parsedData 파싱할 데이터
	 * @private
	 */
	private parsePulseByTimeByDate(parsedData: { Date: string; Values: ResponseBloodPressureData[] }[]) {
		this.pulseDataByDate = new PulseData();

		parsedData.forEach((data: { Date: string; Values: ResponseBloodPressureData[] }) => {
			const thisTime = new Date(data.Date).getTime();
			const valueForDeviceList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Device)
				.toList();
			const valueForManualList: IList<ResponseBloodPressureData> = new List<ResponseBloodPressureData>(data.Values)
				.where(i => i.InputMethod === EnumDataInputMethod.Manual)
				.toList();

			// 측정 데이터가 존재하는 경우
			if(valueForDeviceList.count() > 0) {
				const tempRate = {
					x:thisTime, low:valueForDeviceList.min(i => i.Rate), high:valueForDeviceList.max(i => i.Rate)
				};
				const tempAverage = {
					x:thisTime, y:Math.round(valueForDeviceList.average(i => i.Rate) * 10) / 10
				};

				this.pulseDataByDate.updatedRate.push(tempRate);
				this.pulseDataByDate.updatedAverage.push(tempAverage);
			}

			// 수기 데이터가 존재하는 경우
			if(valueForManualList.count() > 0) {
				const tempRate = {
					x:thisTime, low:valueForManualList.min(i => i.Rate), high:valueForManualList.max(i => i.Rate)
				};
				const tempAverage = {
					x:thisTime, y:Math.round(valueForManualList.average(i => i.Rate) * 10) / 10
				};

				this.pulseDataByDate.updatedRateManual.push(tempRate);
				this.pulseDataByDate.updatedAverageManual.push(tempAverage);
			}
		});
	}
}


class BloodPressureDataRecent {
	updatedDiastolic: any = [];
	updatedSystolic: any = [];
	updatedMean: any = [];
	updatedDiastolicManual: any = [];
	updatedSystolicManual: any = [];
	updatedMeanManual: any = [];
}

class PulseData {
	updatedRate: any = [];
	updatedAverage: any = [];
	updatedRateManual: any = [];
	updatedAverageManual: any = [];
}

class BloodPressureDataByDate {
	updatedDiastolic: any = [];
	updatedDiastolicAverage: any = [];
	updatedSystolic: any = [];
	updatedSystolicAverage: any = [];
	updatedMean: any = [];
	updatedMeanAverage: any = [];
	updatedDiastolicAverageManual: any = [];
	updatedDiastolicManual: any = [];
	updatedSystolicAverageManual: any = [];
	updatedSystolicManual: any = [];
	updatedMeanManual: any = [];
	updatedMeanAverageManual: any = [];
}
