import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService} from './auth.service';
import {EnumResponseResult} from '../models/enums/enum-response-result.model';
import {ResponseData} from '../models/response-data.model';
import {ToastMessageService} from './toast-message.service';
import {queryParams} from '@syncfusion/ej2-base';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

	/**
	 * 생성자
	 * @param authService 인증 서비스 객체
	 * @param router 라우팅 서비스 객체
	 * @param messageService 메세지 서비스 객체
	 */
	constructor(
		private authService: AuthService,
		private router: Router,
		private messageService: ToastMessageService,
	) {
	}

	/**
	 * 페이지 접근 허용 여부
	 * @param route 라우트 객체
	 * @param state 상태 객체
	 */
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// const checkLoginResponse: ResponseData = await this.authService.checkLogin().toPromise();
		// 로그인이 되어있을 경우
		const routeInfo = route.url[0];
		if(routeInfo) {
			if(routeInfo.path.toLowerCase().includes('community')) {
				return true;
			}
		}


		// 로그인인 되어있을 경우
		if(this.authService.isLogined()) {
			return true;
		}
		// 로그인이 되어있지 않을 경우
		else {
			await this.router.navigate(['./account/login', {redUri: state.url}]);
			return false;
		}
	}
}
