import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {LoadingService} from '../../../services/loading.service';
import {filter, finalize} from 'rxjs/operators';
import {EnumResponseResult} from '../../../models/enums/enum-response-result.model';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ToastMessageService} from '../../../services/toast-message.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LocalCacheService} from '../../../services/cache/cache.service';
import {ChangePasswordComponent} from '../../change-password/change-password.component';
import {AuthGuard} from '../../../services/auth.guard';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	// 통신중 플래그
	public inCommunication: boolean = false;

	// 현재 위치한 서브 네비게이션 명
	public subNavName: string = '';

	// 사이트맵 Path
	public currentPath: string = '';

	// 패스정보
	public paths: string[]  = [];

	// 패스 경로 보여짐 여부
	public pathRouteView: boolean = false;

	// 마이페이지 아이콘 상태값
	public myPagePopupState:boolean = false;

	// 사이드 메뉴 활성화 여부
	public sideMenuIconActive:boolean = false;

	// 제품페이지 하위메뉴 상태값
	public selectSubMenuValue:string = '';

	// Status 팝업창
	@ViewChild('statusPopup') statusPopup : ElementRef;

	// 비밀번호 변경 모달
	@ViewChild('changePasswordDialogChild') changePasswordDialogChild: ChangePasswordComponent;

	/**
	 * 생성자
	 * @param translateService 다국어 객체
	 * @param formBuilder 폼 빌더 객체
	 * @param loadingService 로딩 서비스 객체
	 * @param messageService 토스트 메시지 서비스 객체
	 * @param routerService 라우팅 서비스 객체
	 * @param authService 인증 서비스 객체
	 * @param activatedRoute 라우팅 서비스 객체
	 * @param localCacheService 캐쉬 서비스 객체
	 */
	constructor(
		private translateService: TranslateService,
		private formBuilder: FormBuilder,
		private loadingService: LoadingService,
		private messageService: ToastMessageService,
		private routerService: Router,
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private localCacheService: LocalCacheService,
		private authGuard: AuthGuard,
	) {
		// 라우터 변경시 이벤트 구독
		this.routerService.events.pipe(filter(events=>events instanceof NavigationEnd))
			.subscribe(event => {
				const navigationEnd = event as NavigationEnd;
				const url = navigationEnd.urlAfterRedirects.toLocaleLowerCase();

				// 마이 아이콘 팝업 상태 초기화
				this.myPagePopupState = false;

				// 서브 네비명 초기화
				this.subNavName = '';

				// 경로 표기 초기화
				this.pathRouteView = false;

				// 패스명 초기화
				this.currentPath = '';

				// 패스명 변수 초기화
				this.paths = [];

				const splitUrl = url.split('/').filter(i => i !== '');
				switch (splitUrl[0]) {
					case 'healthcare':
						this.pathRouteView = true;
						this.subNavName = 'healthcare';
						this.paths.push('헬스케어');

						// 서브 Path 가 존재하는 경우
						if(splitUrl.length > 1) {
							const inner = splitUrl[1].toLocaleLowerCase();
							// 서브 명이 존재할경우
							if(inner) {
								// 서브명에 따른 Path 텍스트 변경
								switch (inner) {
									case 'glucose':
										this.paths.push('혈당계');
										break;
									case 'smart-band':
										this.paths.push('스마트밴드');
										break;
									case 'composition-weight-meter':
										this.paths.push('체성분/체중계');
										break;
									case 'thermometer':
										this.paths.push('체온계');
										break;
									case 'blood-pressure':
										this.paths.push('혈압계');
										break;
									case 'food-lens':
										this.paths.push('식단');
										break;
									case 'self-diagnosis':
										this.paths.push('자가진단 프로그램');
										break;
									case 'recommended-diet':
										this.paths.push('추천식단');
										break;
									case 'medication':
										this.paths.push('복약');
										break;
								}
							}
						}
						break;
					case 'products':
						this.subNavName = 'products';
						break;
					case 'mypage':
						this.pathRouteView = true;
						this.subNavName = 'mypage';
						this.paths.push('마이페이지');

						// 서브 Path 가 존재하는 경우
						if(splitUrl.length > 1) {
							const inner = splitUrl[1].toLocaleLowerCase();
							// 서브 명이 존재할경우
							if(inner) {
								// 서브명에 따른 Path 텍스트 변경
								switch (inner) {
									case 'profile':
										this.subNavName = 'myPageNonPath';
										this.paths.push('프로필');
										break;
									case 'alarm-list':
										this.subNavName = 'myPageNonPath';
										break;
									case 'shared-health-data':
										this.subNavName = 'myPageNonPath';
										break;
									case 'my-library':
										this.paths.push('마이 라이브러리');
										break;
									case 'joined-community':
										this.paths.push('가입한 커뮤니티');
										break;
								}
							}
						}
						break;
					case 'community':
						this.pathRouteView = true;
						this.subNavName = 'community';
						this.paths.push('커뮤니티');

						// 서브 Path 가 존재하는 경우
						if(splitUrl.length > 1) {
							const inner = splitUrl[1].toLocaleLowerCase();
							// 서브 명이 존재할경우
							if(inner) {
								// 서브명에 따른 Path 텍스트 변경
								switch (inner) {
									case 'joined':
										this.paths.push('가입한 커뮤니티');
										break;
									case 'request-to-open':
										this.paths.push('커뮤니티 개설 요청');
										break;
									case 'management':
										this.paths.push('커뮤니티 관리');
										break;
								}
							}
						}
						break;
				}
			});
	}

	/**
	 * ngOnInit
	 */
	ngOnInit(): void {
	}

	/**
	 * 대상 경로에 해당하는 경우 True 리턴
	 * @param path 경로
	 * @param exactMatch 정확하게 일치하는지 검사 여부
	 */
	isActive(path: string, exactMatch: boolean = false): boolean {
		if(exactMatch)
			return window.location.pathname === path;
		else
			return window.location.pathname.includes(path);
	}

	/**
	 * 로그아웃
	 */
	logout() {
		this.inCommunication = true;
		this.loadingService.showLoading();
		this.authService.logout().pipe(
			finalize(() => {
				this.inCommunication = false;
				this.myPagePopupState = false;
				this.loadingService.hideLoading();
			}),
		).subscribe(result => {
				// 결과가 성공인 경우
				if (result.Result === EnumResponseResult.Success)
					this.routerService.navigate(['/']);
			},
			(httpErrorResponse: HttpErrorResponse) => {
				// 서버와 통신에 실패 했을 경우
				if (!(httpErrorResponse.ok as boolean))
					this.messageService.error(this.translateService.instant('EM_COMMON__COMMUNICATION_ERROR_TO_API'));
			});
	}

	/**
	 * 마이페이지 팝업메뉴 활성화 여부
	 */
	onPopupMyStatus() {
		// 로그인 한 상태인경우
		if(this.authService.isLogined())
			this.myPagePopupState = !this.myPagePopupState;
		// 로그인 하지 않은경우
		else
			this.routerService.navigate(['account/login']);
	}

	/**
	 * 사이드 메뉴 활성화 여부
	 */
	onSideMenuActive() {

		this.sideMenuIconActive = !this.sideMenuIconActive;

		if(this.sideMenuIconActive)
			document.body.classList.add('hidden-overflow');
		else
			document.body.classList.remove('hidden-overflow');


	}

	/**
	 * 비밀번호 변경 팝업 오픈
	 */
	showChangePasswordModal() {
		this.changePasswordDialogChild.openDialog();
	}

	/**
	 * 제품페이지 제품별 서브메뉴 팝업 오픈
	 * @param value 선택된 제품 메인명
	 */
	onClickProductSubMenu(value: string) {
		if (this.selectSubMenuValue === value)
			this.selectSubMenuValue = '';
		else {
			this.selectSubMenuValue = value;
		}
	}

	/**
	 * 제품페이지 이동 후 팝업 닫기
	 */
	closePopup() {
		this.selectSubMenuValue = '';
	}

	goShopping() {
		this.messageService.warning('준비중입니다.');
	}
}
