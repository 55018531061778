import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {ResponseData} from '../../models/response-data.model';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ResponseList} from '../../models/response-list.model';
import {ResponseUpload} from '../../models/response/common/response-upload.model';
import {RequestMoveImages} from '../../models/request/common/request-move-images.model';
import {FileInfo} from '@syncfusion/ej2-inputs';

@Injectable({
	providedIn: 'root'
})
export class UploadProviderProvider {
	// API URI 정보
	private readonly API_URI: string = `${environment.apiUrl}/upload`;

	/**
	 * 생성자
	 */
	constructor(
		private httpClient: HttpClient
	) {}

	/**
	 * 폼으로 전송되는 파일 데이터를 임시 폴더에 저장한 결과를 리턴한다 .
	 *  @param contentType 컨텐츠 타입
	 *  @param fileName    파일명
	 *  @param base64      BASE64 형식의 파일 내용
	 */
	saveFile(contentType: string , fileName: string , base64: string): Observable<ResponseData> {
		const formData = new FormData();
		formData.append('contentType',`image/${contentType}`);
		formData.append('fileName',fileName);
		formData.append('base64', base64);

		return this.httpClient.post<ResponseList<ResponseUpload>>(`${this.API_URI}/saveFile`, formData ,)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 파일을 업로드하여 임시 폴더에 저장한다.
	 */
	upload(file: FileInfo): Observable<ResponseData> {
		const formData = new FormData();
		formData.append('file',file.rawFile,file.name);

		return this.httpClient.post<ResponseList<ResponseUpload>>(`${this.API_URI}`,formData)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 임시 폴더에 업로드한 파일을 삭제한다.
	 * @param fileNames  삭제할 파일 목록
	 * @param ignoreError 에러를 무시할지 여부
	 */
	delete(fileNames:string[], ignoreError: boolean ): Observable<ResponseData> {
		const param : any = {
			fileNames ,
			ignoreError
		};

		return this.httpClient.delete<ResponseData>(`${this.API_URI}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 업로드한 이미지 파일을 URL에서 사용할 수 있도록 data의 temp 폴더로 이동한다.
	 * @param request 요청 객체  .jpg", ".jpeg", ".png", ".svg" 확장자만 허용
	 */
	moveImages(request:RequestMoveImages): Observable<ResponseData> {
		return this.httpClient.post<ResponseList<ResponseUpload>>(`${this.API_URI}/MoveImages`, request )
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

}
