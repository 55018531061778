import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoadingService} from '../../../shared/services/loading.service';
import {ToastMessageService} from '../../../shared/services/toast-message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {LocalCacheService} from '../../../shared/services/cache/cache.service';
import {AccountProvider} from '../../../shared/services/providers/account.provider';
import {finalize} from 'rxjs/operators';
import {EnumResponseResult} from '../../../shared/models/enums/enum-response-result.model';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
	/**
	 * 통신 중인지 여부
	 */
	inCommunication: boolean = false;
	/**
	 * 비밀번호 분실 요청 폼
	 */
	forgotForm: FormGroup;

	/**
	 * 생성자
	 * @param translateService 다국어 객체
	 * @param formBuilder 폼 빌더 객체
	 * @param loadingService 로딩 서비스 객체
	 * @param messageService 토스트 메시지 서비스 객체
	 * @param routerService 라우팅 서비스 객체
	 * @param authService 인증 서비스 객체
	 * @param activatedRoute 라우팅 서비스 객체
	 * @param localCacheService 캐쉬 서비스 객체
	 * @param accountService 회원 서비스 객체
	 */
	constructor(
		private translateService: TranslateService,
		private formBuilder: FormBuilder,
		private loadingService: LoadingService,
		private messageService: ToastMessageService,
		private routerService: Router,
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private localCacheService: LocalCacheService,
		private accountService: AccountProvider,
	) {
	}

	/**
	 * ngOnInit
	 */
	ngOnInit(): void {
		this.forgotForm = this.formBuilder.group({
			Protocol: [environment.protocol, [Validators.required]],
			Host: [environment.host, [Validators.required]],
			LoginId: ['', [Validators.required]],
		}, {});
	}

	/**
	 * 패스워드 정보를 리셋한다
	 */
	requestPasswordReset() {
		// 유효한 폼인경우
		if (this.forgotForm.valid) {
			this.inCommunication = true;
			this.loadingService.showLoading();
			this.accountService.forgotPassword(this.forgotForm.getRawValue())
				.pipe(
					finalize(() => {
						this.inCommunication = false;
						this.loadingService.hideLoading();
					}),
				)
				.subscribe(result => {
						// 결과가 성공인 경우
						if (result.Result === EnumResponseResult.Success) {
							// 메세지가 존재하는 경우
							if (result.Message)
								this.messageService.success(result.Message);

							this.routerService.navigate(['/']);
						}
						// 결과가 실패인경우
						else
							this.messageService.error(`[${result.Code}] ${result.Message}`);
					},
					(httpErrorResponse: HttpErrorResponse) => {
						// 서버와 통신에 실패 했을 경우
						if (!(httpErrorResponse.ok as boolean))
							this.messageService.error(this.translateService.instant('EM_COMMON__COMMUNICATION_ERROR_TO_API'));
					});
		}
	}

	/**
	 * 로그인 화면으로 이동
	 */
	public navigateLogin(): void {
		this.routerService.navigate(['./account/login', {}]);
	}
}
