import {AfterViewInit, Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Overlay} from '@angular/cdk/overlay';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {List} from 'linq-collections';
import {L10n, loadCldr} from '@syncfusion/ej2-base';
import {HttpClient} from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {DocumentBehaviorService} from '../shared/services/document-behavior.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
	/**
	 * 라우팅시 기본 레이아웃 ( 헤더 , 푸터등 ) 포함 여부
	 */
	withDefaultLayout: boolean = true;
	/**
	 * 제외할 레이아웃 경로
	 */
	private exceptLayoutPaths: List<string> = new List<string>();

	// root 클릭 이벤트
	@HostListener('document:click', ['$event'])
	documentClick(event:any): void {
		// 클릭이벤트 전달
		this.documentBehaviorService.nextClickOutside(event);
	}

	/**
	 * 생성자
	 * @param translate 다국어 모듈
	 * @param renderer 렌더러 모듈
	 * @param overlay 오버레이 모듈
	 * @param router 라우터
	 * @param activatedRoute 액티베이트 라우터
	 * @param httpClient httpClient
	 * @param documentBehaviorService document 클릭 이벤트 서비스
	 */
	constructor(
		private translate: TranslateService,
		private renderer: Renderer2,
		private overlay: Overlay,
		private router:Router,
		private activatedRoute:ActivatedRoute  ,
		private httpClient: HttpClient ,
		private documentBehaviorService: DocumentBehaviorService ,
	) {
		// 제외할 레이아웃 경로추가
		this.exceptLayoutPaths.push('error');
		this.exceptLayoutPaths.push('app');

		// 라우터 변경시 이벤트 구독
		this.router.events.pipe(
			filter(events=>events instanceof NavigationEnd))
			.subscribe(event => {
				const navigationEnd = event as NavigationEnd;

				// 제외할 라우팅 주소인경우 Flag 를 변경한다
				this.withDefaultLayout = !this.exceptLayoutPaths.any(except => navigationEnd.urlAfterRedirects.toLocaleLowerCase().indexOf(except) > -1);
			});
	}


	/**
	 * ngOnInit
	 */
	ngOnInit() {
		forkJoin({
			numberingSystems :  this.httpClient.get('assets/cldr-data/numberingSystems.json').toPromise() ,
			gregorian        :  this.httpClient.get('assets/cldr-data/ca-gregorian.json').toPromise()     ,
			numbers	         :  this.httpClient.get('assets/cldr-data/numbers.json').toPromise()          ,
			timeZoneNames    :	this.httpClient.get('assets/cldr-data/timeZoneNames.json').toPromise()    ,
			locale			 : 	this.httpClient.get('assets/cldr-data/locale/ko.json').toPromise()
		})
			.subscribe(({numberingSystems,gregorian,numbers,timeZoneNames,locale}) => {
				loadCldr(numberingSystems, gregorian, numbers, timeZoneNames,locale);
				L10n.load(locale);

			});
	}

	/**
	 * ngAfterViewInit
	 */
	ngAfterViewInit(): void {
	}
}
