import {Injectable} from '@angular/core';
import {Toast} from '@syncfusion/ej2-notifications';
import {ToastImplmentModel} from '../models/commondata/common-toast-data.model';

@Injectable({providedIn: 'root'})
/**
 * 토스트 서비스
 */
export class ToastMessageService {
	/**
	 * 토스트 dom 객체
	 */
	public element: HTMLElement;

	/**
	 * 토스트 Object
	 */
	public toastObj: Toast;

	/**
	 * 토스트 모델
	 */
	public toastModel: { toastObj: any, target: HTMLElement };

	/**
	 * 생성자
	 */
	constructor() {
	}

	/**
	 * 토스트 객체를 리턴한다
	 * @private
	 */
	private getToastInstance(): Toast {
		// 토스트 div 추가
		this.element = document.createElement('div');
		document.body.appendChild(this.element);

		// 오브젝트가 존재하지 않을 경우
		if (this.toastObj == null) {
			const model: ToastImplmentModel = new ToastImplmentModel();
			this.toastObj = new Toast(model, this.element);
			this.toastObj.position = {X: 'Center'};
		}

		this.toastObj.showCloseButton = true;
		this.toastObj.newestOnTop = true;
		return this.toastObj;
	}


	/**
	 * 성공 메세지를 표시 한다
	 * @param message 표시 메세지
	 * @param timeout 유지시간
	 */
	success(message: string, timeout: number = 1000 * 3): void {
		const toastInstance = this.getToastInstance();
		toastInstance.timeOut = timeout;
		toastInstance.icon = 'e-success toast-icons';
		toastInstance.cssClass = 'e-toast-success';
		toastInstance.content = message;
		toastInstance.show();
	}

	/**
	 * 실패 메세지를 표시 한다
	 * @param message 표시 메세지
	 * @param timeout 유지시간
	 */
	error(message: string, timeout: number = 1000): void {
		const toastInstance = this.getToastInstance();
		// 에러메세지는 한시간 까지 유지
		toastInstance.timeOut = timeout * 10 * 60;
		toastInstance.icon = 'e-error toast-icons';
		toastInstance.cssClass = 'e-toast-danger';
		toastInstance.content = message;
		toastInstance.show();
	}

	/**
	 * 경고 메세지를 표시 한다
	 * @param message 표시 메세지
	 * @param timeout 유지시간
	 */
	warning(message: string, timeout: number = 1000 * 3): void {
		const toastInstance = this.getToastInstance();
		toastInstance.timeOut = timeout;
		toastInstance.icon = 'e-error toast-icons';
		toastInstance.cssClass = 'e-toast-warning';
		toastInstance.content = message;
		toastInstance.show();
	}

	/**
	 * 토스트를 생성한다
	 * @param title 타이틀
	 * @param content 내용
	 */
	showToast(title: string, content: string): void {
		// 토스트 객체 생성
		const model: ToastImplmentModel = new ToastImplmentModel();
		model.title = title;
		model.content = content;

		const toastInstance = this.getToastInstance();
		toastInstance.show();
	}

	/**
	 * 토스트를 가린다
	 * @param toastModel
	 */
	hideToast(toastModel: { toastObj: any, target: HTMLElement }): void {
		if (this.toastModel.toastObj) {
			this.toastModel.toastObj.hide();
			this.toastModel.toastObj.destroy();
			document.body.removeChild(this.toastModel.target);
		}
	}

	/**
	 * 전체 토스트를 가린다
	 * @param toastObj
	 */
	hideToastAll(toastObj: Toast): void {
		if (toastObj) {
			toastObj.hide('All');
		}
	}
}
