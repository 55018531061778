import {Injectable} from '@angular/core';
import {CacheValue} from './cache-value.model';

const DEFAULT_EXPIRES = Number.MAX_VALUE / 1000 / 60 / 60;   // 만료시간 기본 설정

/**
 * 세션 캐시 서비스
 */
@Injectable()
export class SessionCacheService {
	/**
	 * 생성자
	 */
	constructor() {
	}

	/**
	 * 만료 시간 확인
	 * @param expires 만료 시간
	 */
	static isExpired(expires: number) {
		try {
			return (expires * 1000 * 60 * 60 <= Date.now());
		} catch (e) {
			return false;
		}
	}

	/**
	 * 캐시에서 가져오기
	 * @param key 키 이름
	 */
	public get(key: string) {
		// 해당 key가 존재할 경우
		if (this.has(key)) {
			const value: CacheValue = JSON.parse(sessionStorage.getItem(key) as string);

			// 만료시간 이내이면 cache 값 return
			if (!SessionCacheService.isExpired(value.expires as number)) {
				return value.value;
			}
			// 만료시간이 지난 경우 cache에서 해당 key 삭제
			else {
				this.remove(key);
				return null;
			}
		}
		// 해당 key가 존재하지 않을 경우
		else {
			return null;
		}
	}

	/**
	 * 캐시에 저장
	 * @param key 키 이름
	 * @param value 값
	 * @param expires 만료 시간
	 */
	public set(key: string, value: any, expires?: number) {
		try {
			const cacheValue: CacheValue = new CacheValue();
			cacheValue.value = value;
			cacheValue.expires = expires ? expires : DEFAULT_EXPIRES;

			sessionStorage.setItem(key, JSON.stringify(cacheValue));
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * 해당 key를 캐시에서 삭제
	 * @param key 키 이름
	 */
	public remove(key: string) {
		sessionStorage.removeItem(key);
	}

	/**
	 * 해당 key가 캐시에 존재하는지 확인
	 * @param key 키 이름
	 */
	public has(key: string) {
		return !!sessionStorage.getItem(key);
	}

	/**
	 * 캐시에서 key와 value가 모두 일치하는 값이 있는지 확인
	 * @param key 키 이름
	 * @param value 값
	 */
	public hasValue(key: string, value: string) {
		// 해당 key가 캐시에 존재할 경우
		if (this.has(key)) {
			// 해당 key의 값을 가져온다
			const cacheValue = this.get(key);

			// 값이 있을 경우
			if (cacheValue) {
				// 값에서 찾고자 하는 값이 존재하는지 확인
				if (typeof (cacheValue) === 'string' || typeof (cacheValue) === 'number') {
					return cacheValue === value;
				} else {
					return cacheValue.indexOf(value) >= 0;
				}
			}
			// 값이 없는 경우
			else {
				return false;
			}
		}
		// 해당 key가 캐시에 존재하지 않을 경우
		else {
			return false;
		}
	}

	/**
	 * 캐시 모두 삭제
	 */
	public clear() {
		sessionStorage.clear();
	}
}

/**
 * 로컬 캐시 서비스
 */
@Injectable()
export class LocalCacheService {
	/**
	 * 생성자
	 */
	constructor() {
	}

	/**
	 * 만료 시간 확인
	 * @param expires 만료 시간
	 */
	static isExpired(expires: number) {
		try {
			return (expires * 1000 * 60 * 60 <= Date.now());
		} catch (e) {
			return false;
		}
	}

	/**
	 * 캐시에서 가져오기
	 * @param key 키 이름
	 */
	public get(key: string) {
		// 해당 key가 존재할 경우
		if (this.has(key)) {
			const value: CacheValue = JSON.parse(localStorage.getItem(key) as string);

			// 만료시간 이내이면 cache 값 return
			if (!LocalCacheService.isExpired(value.expires as number)) {
				return value.value;
			}
			// 만료시간이 지난 경우 cache에서 해당 key 삭제
			else {
				this.remove(key);
				return null;
			}
		}
		// 해당 key가 존재하지 않을 경우
		else {
			return null;
		}
	}

	/**
	 * 캐시에 저장
	 * @param key 키 이름
	 * @param value 값
	 * @param expires 만료 시간
	 */
	public set(key: string, value: any, expires?: number) {
		try {
			const cacheValue: CacheValue = new CacheValue();
			cacheValue.value = value;
			cacheValue.expires = expires ? expires : DEFAULT_EXPIRES;

			localStorage.setItem(key, JSON.stringify(cacheValue));
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * 해당 key를 캐시에서 삭제
	 * @param key 키 이름
	 */
	public remove(key: string) {
		localStorage.removeItem(key);
	}

	/**
	 * 해당 key가 캐시에 존재하는지 확인
	 * @param key 키 이름
	 */
	public has(key: string) {
		return !!localStorage.getItem(key);
	}

	/**
	 * 캐시에서 key와 value가 모두 일치하는 값이 있는지 확인
	 * @param key 키 이름
	 * @param value 값
	 */
	public hasValue(key: string, value: string) {
		// 해당 key가 캐시에 존재할 경우
		if (this.has(key)) {
			// 해당 key의 값을 가져온다
			const cacheValue = this.get(key);

			// 값이 있을 경우
			if (cacheValue) {
				// 값에서 찾고자 하는 값이 존재하는지 확인
				if (typeof (cacheValue) === 'string' || typeof (cacheValue) === 'number') {
					return cacheValue === value;
				} else {
					return cacheValue.indexOf(value) >= 0;
				}
			}
			// 값이 없는 경우
			else {
				return false;
			}
		}
		// 해당 key가 캐시에 존재하지 않을 경우
		else {
			return false;
		}
	}

	/**
	 * 캐시 모두 삭제
	 */
	public clear() {
		localStorage.clear();
	}
}
