import {ToastModel} from '@syncfusion/ej2-notifications';

/**
 * Syncfusion 토스트 모델 구현체
 */
export class ToastImplmentModel implements ToastModel {
	// 생성자
	constructor() {
	}

	public title: string = '';
	public content: string = '';
}
