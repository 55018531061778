import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/services/auth.guard';
import {AppLinkComponent} from './app-link/app-link.component';

// Root 라우팅
const routes: Routes = [
	{
		path: '',
		redirectTo: 'main',
		pathMatch: 'full'
	},
	{
		path: 'app' ,
		component: AppLinkComponent
	},
	{
		path: 'account',
		loadChildren: () => import('./accounts/accounts.module').then(i => i.AccountsModule),
	},
	{
		path: 'home-no-login',
		loadChildren: () => import('./home-no-login/home-no-login.module').then(i => i.HomeNoLoginModule),
	},
	{
		path: 'main',
		loadChildren: () => import('./main/main.module').then(i => i.MainModule),
	},
	{
		path: 'products',
		loadChildren: () => import('./products/products.module').then(i => i.ProductsModule),
		// canActivate: [AuthGuard],
	},
	{
		path: 'healthcare',
		loadChildren: () => import('./healthcare/healthcare.module').then(i => i.HealthcareModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'contents',
		loadChildren: () => import('./contents/contents.module').then(i => i.ContentsModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'community',
		loadChildren: () => import('./community/community.module').then(i => i.CommunityModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'mypage',
		loadChildren: () => import('./mypage/mypage.module').then(i => i.MypageModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'errors',
		loadChildren: () => import('./errors/errors.module').then(i => i.ErrorsModule),
	},
	{
		path: '**',
		redirectTo: '/errors/404',
	}

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, useHash: false})
		// , scrollPositionRestoration: 'enabled'
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}


