import {Component, Input, ViewChild} from '@angular/core';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {finalize} from "rxjs/operators";
import {EnumResponseResult} from "../../models/enums/enum-response-result.model";
import {TranslateService} from "@ngx-translate/core";
import {LoadingService} from "../../services/loading.service";
import {ToastMessageService} from "../../services/toast-message.service";
import {AccountProvider} from "../../services/providers/account.provider";
import {Router} from "@angular/router";
import {CustomValidators} from "ngx-custom-validators";

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent{
	/**
	 * 통신 중인지 여부
	 */
	inCommunication: boolean = false;
	/**
	 * 비밀번호 변경 폼
	 */
	formGroupChangePassword: FormGroup;
	/**
	 * X 닫기 아이콘 보여주기 여부
	 */
	@Input() isShowCloseIcon: boolean;
	/**
	 * 자기 자신
	 */
	@ViewChild('changePasswordModal') changePasswordModal: DialogComponent ;

	/**
	 * 본인의 컨트롤 객체
	 */
	public targetElement: HTMLElement;

	/**
	 * 생성자
	 * @param translateService 다국어 객체
	 * @param formBuilder 폼 빌더 객체
	 * @param routerService 라우팅 서비스 객체
	 * @param loadingService 로딩 서비스 객체
	 * @param messageService 토스트 메시지 서비스 객체
	 * @param accountService 회원 서비스 객체
	 */
	constructor(
		private translateService: TranslateService,
		private formBuilder: FormBuilder,
		private routerService: Router,
		private loadingService: LoadingService,
		private messageService: ToastMessageService,
		private accountService: AccountProvider,
	) {
	}

	/**
	 *  ngOnInit
	 */
	ngOnInit(): void {
		this.formGroupChangePassword = this.formBuilder.group({
			Password: ['', [Validators.required, Validators.minLength(6)]],
			NewPassword: ['', [Validators.required, Validators.minLength(6)]],
			NewConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
		}, {});

		this.formGroupChangePassword.controls.NewConfirmPassword.setValidators([Validators.required, CustomValidators.equalTo(this.formGroupChangePassword.controls.NewPassword)]);
	}

	/**
	 * 다이얼로그를 연다
	 */
	openDialog(): void {
		this.formGroupChangePassword.setValue({
			Password: '',
			NewPassword: '',
			NewConfirmPassword: '',
		});

		this.changePasswordModal.show();
	}

	/**
	 * 다이얼로그를 닫는다
	 */
	closeDialog(): void {
		this.changePasswordModal.hide();
	}

	/**
	 * 비밀번호 변경
	 */
	async onSubmit() {
		// 폼 데이터가 유효한 경우
		if (this.formGroupChangePassword.valid) {
			this.inCommunication = true;
			this.loadingService.showLoading();

			// 비밀번호 변경을 요청한다.
			this.accountService.changePassword(this.formGroupChangePassword.getRawValue())
				.pipe(
					finalize(() => {
						this.loadingService.hideLoading();
						this.inCommunication = false;
						this.closeDialog();
					})
				)
				.subscribe((response) => {
					// 결과가 성공인 경우
					if (response.Result === EnumResponseResult.Success) {
						if(response.Message)
							this.messageService.success(response.Message);

						this.closeDialog();
					}
					// 결과가 실패인 경우
					else {
						this.messageService.error('[' + response.Code + '] ' + response.Message);
					}
				});
		}
	}
}
