import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ResponseData} from '../../models/response-data.model';
import {RequestRegister} from '../../models/request/account/request-register.model';
import {catchError, map} from 'rxjs/operators';
import {RequestPhoneVerifyCode} from '../../models/request/account/request-phone-verify-code.model';
import {ResponseLogin} from '../../models/response/account/response-login.model';
import {EnumResponseResult} from '../../models/enums/enum-response-result.model';
import {SessionCacheService} from '../cache/cache.service';
import {ResponseList} from '../../models/response-list.model';
import {ResponseClaim} from '../../models/response/account/response-claim.model';
import {RequestForgetPassword} from '../../models/request/account/request-forget-password.model';
import {RequestResetPassword} from '../../models/request/account/request-reset-password.model';
import {RequestConfirmEmail} from '../../models/request/account/request-confirm-email.model';
import {ResponseAgencyPushMessage} from '../../models/response/agency/response-agency-push-message.model';
import { ResponsePatientHealthReport } from '../../models/response/patient/response-patient-health-report.model';
import { RequestUpdateProfile } from '../../models/request/account/request-update-profile.model';
import { RequestChangePassword } from '../../models/request/account/request-change-password.model';
import {ResponseUserShareMetaData} from '../../models/response/account/response-user-share-meta-data';
import {ResponseUserShareHealthCareData} from '../../models/response/account/response-user-share-health-care-data';
import {RequestAddUserShareHealthCareData} from '../../models/request/account/request-add-user-share-health-care-data';
import {RequestUpdateUserSharePermission} from '../../models/request/account/request-update-user-share-permission';
import { RequestUpdateProfilePhoto } from '../../models/request/account/request-update-profile-photo.model';

@Injectable({
	providedIn: 'root'
})
export class AccountProvider {
	/**
	 * 클레임 캐시 명
	 * @private
	 */
	private readonly CACHE_CLAIM: string = 'claims';

	/**
	 * 회원 정보 URL
	 */
	private readonly ACCOUNT_URI: string = `${environment.apiUrl}/Account`;

	/**
	 * 생성자
	 * @param httpClient httpClient 객체
	 * @param sessionCacheService sessionCacheService 객체
	 */
	constructor(private httpClient: HttpClient,
				private sessionCacheService: SessionCacheService) {
	}


	/**
	 * 인증번호를 요청한다.
	 * @param phoneNumber 핸드폰 번호
	 */
	requestVerifyCode(phoneNumber: string): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/VerifyCode`, new RequestPhoneVerifyCode(phoneNumber))
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 회원 가입
	 * @param request 회원가입 요청 객체
	 */
	registerMember(request: RequestRegister): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/Register`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 비밀번호 찾기
	 */
	forgotPassword(request: RequestForgetPassword): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/ForgotPassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 핸드폰 인증과 함께 외부 계정 로그인 처리
	 * @param phoneNumber 핸드폰 번호
	 * @param verifyCode 인증 코드
	 */
	externalLoginWithPhone(phoneNumber: string, verifyCode: string) {
		return this.httpClient.post<ResponseData<ResponseLogin>>(`${this.ACCOUNT_URI}/externallogin/${phoneNumber}/${verifyCode}`, null)
			.pipe(
				map(result => {
					if (result.Result === EnumResponseResult.Success) {
						this.setLogin();
						const responseData = new ResponseData();
						responseData.Result = EnumResponseResult.Success;
						// @ts-ignore
						this._loginSource.next(responseData);
					}
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인한 사용자의 권한 가져오기
	 */
	getClaims(): Observable<ResponseList<ResponseClaim>> {
		return this.httpClient.get<ResponseList<ResponseClaim>>(`${this.ACCOUNT_URI}/claims`);
	}

	/**
	 * 로그인 처리
	 * @private
	 */
	private async setLogin() {
		// 기존 권한 삭제 후 새로 입력
		this.sessionCacheService.remove(this.CACHE_CLAIM);

		const claims = await this.getClaims().toPromise();
		if (claims) {
			this.sessionCacheService.set(this.CACHE_CLAIM, claims.Data.Items.map(i => i.ClaimValue));
		}
	}

	/**
	 * 부가정보 수정
	 */
	updateProfile(request: RequestUpdateProfile): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/Profile`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 프로필 이미지 수정
	 */
	updateProfilePhoto(request: RequestUpdateProfilePhoto): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/Profile/Photo`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸시 메시지 목록을 가져온다.
	 */
	getPushMessages(): Observable<ResponseList<ResponseAgencyPushMessage>> {
		return this.httpClient.get<ResponseList<ResponseAgencyPushMessage>>(`${this.ACCOUNT_URI}/PushMessages`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸시 메시지를 읽음 처리 한다.
	 */
	readPushMessage(id: string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/PushMessages/${id}`, {})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 푸시 메시지 삭제
	 */
	removePushMessage(id: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.ACCOUNT_URI}/PushMessages/${id}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 전체 사용자의 건강 리포트를 가져온다.
	 * @param param 검색 파라미터
	 */
	getReports(param: any = null): Observable<ResponseList<ResponsePatientHealthReport>> {
		return this.httpClient.get<ResponseList<ResponsePatientHealthReport>>(`${this.ACCOUNT_URI}/Reports`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 건강 리포트를 읽음 처리 한다.
	 */
	readReport(id: string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/Reports/${id}`, {})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 읽지 않은 푸시 메시지 개수를 가져온다.
	 */
	getUnreadPushMessageCount(): Observable<ResponseData<number>> {
		return this.httpClient.get<ResponseData<number>>(`${this.ACCOUNT_URI}/PushMessages/Unread/Count`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 읽지 않은 건강 리포트 개수를 가져온다.
	 */
	getUnreadReportCount(): Observable<ResponseData<number>> {
		return this.httpClient.get<ResponseData<number>>(`${this.ACCOUNT_URI}/Reports/Unread/Count`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 특정 사용자의 건강 리포트를 삭제한다.
	 * @param dataId 데이터 아이디
	 */
	removeReport(dataId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.ACCOUNT_URI}/Reports/${dataId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 비밀번호 재설정
	 */
	resetPassword(request: RequestResetPassword): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/ResetPassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 비밀번호 재설정
	 */
	changePassword(request: RequestChangePassword): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/ChangePassword`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 이메일 인증
	 */
	confirmEmail(request: RequestConfirmEmail): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/ConfirmEmail`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 건강데이터 공유 권한 리스트를 가져온다.
	 */
	getUserShareMetaDataListAsync(): Observable<ResponseList<ResponseUserShareMetaData>> {
		return this.httpClient.get<ResponseList<ResponseUserShareMetaData>>(`${this.ACCOUNT_URI}/HealthDataShare/PermissionMetas`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 건강데이터 공유 받은 목록의 데이터 수를 가져온다
	 */
	getHealthCareDataShareFromInviteListCount(): Observable<ResponseData<number>> {
		return this.httpClient.get<ResponseData<number>>(`${this.ACCOUNT_URI}/HealthDataShare/FromInvite/Count`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인한 사용자의 건강데이터 공유 받은 목록을 가져온다
	 * @param param 파라미터
	 */
	getHealthCareDataShareFromInviteList( param:any): Observable<ResponseList<ResponseUserShareHealthCareData>> {
		return this.httpClient.get<ResponseList<ResponseUserShareHealthCareData>>(`${this.ACCOUNT_URI}/HealthDataShare/FromInvite`, {params:param})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 건강데이터 공유한 목록의 데이터 수를 가져온다
	 */
	getHealthCareShareDataToInviteListCount(): Observable<ResponseData<number>> {
		return this.httpClient.get<ResponseData<number>>(`${this.ACCOUNT_URI}/HealthDataShare/ToInvite/Count`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 건강데이터 공유 받은 목록을 가져온다
	 * @param param 파라미터
	 */
	getHealthCareShareDataToInviteList( param:any): Observable<ResponseList<ResponseUserShareHealthCareData>> {
		return this.httpClient.get<ResponseList<ResponseUserShareHealthCareData>>(`${this.ACCOUNT_URI}/HealthDataShare/ToInvite`, {params:param})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 건강데이터 공유 초대를 승인한다
	 * @param shareHealthCareDataId 데이터 키값
	 */
	acceptInvitationAsync( shareHealthCareDataId:string): Observable<ResponseData> {
		return this.httpClient.get<ResponseData>(`${this.ACCOUNT_URI}/HealthDataShare/FromInvite/AcceptInvite/${shareHealthCareDataId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 초대받은 건강데이터 공유를 중지한다
	 * @param shareHealthCareDataId 데이터 키값
	 */
	putStopShareFromInviteAsync( shareHealthCareDataId:string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/HealthDataShare/FromInvite/StopShare/${shareHealthCareDataId}`,{})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 초대한 건강데이터 공유를 중지한다
	 * @param shareHealthCareDataId 데이터 키값
	 */
	putStopShareToInviteSentAsync( shareHealthCareDataId:string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/HealthDataShare/ToInvite/StopShare/${shareHealthCareDataId}`,{})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인한 사용자의 건강데이터 공유를 초대 취소한다 ( 초대된 상태에서만 가능 )
	 * @param shareHealthCareDataId 데이터 키값
	 */
	putCancelToInviteSentAsync( shareHealthCareDataId:string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/HealthDataShare/ToInvite/CancelInvitation/${shareHealthCareDataId}`,{})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 초대한 건강데이터의 권한을 수정한다
	 * @param request 요청 파라미터
	 */
	putFromInviteModifyHealthDataPermissionAsync( request:RequestUpdateUserSharePermission ): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.ACCOUNT_URI}/HealthDataShare/ToInvite/Modify`,request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 로그인한 사용자의 건강 데이터 공유 초대를 특정사용자에게 보낸다
	 * @param request 요청 파라미터
	 */
	postSendToInviteHealthDataShareAsync( request:RequestAddUserShareHealthCareData ): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.ACCOUNT_URI}/HealthDataShare/ToInvite`,request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 초대할 대상 사용자를 검색한다
	 * @param param 파라미터
	 */
	searchPossibleInviteUser( param:any): Observable<ResponseList<ResponseUserShareHealthCareData>> {
		return this.httpClient.get<ResponseList<ResponseUserShareHealthCareData>>(`${this.ACCOUNT_URI}/HealthDataShare/SearchUser`, {params:param})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
}
