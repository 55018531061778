import {Injectable} from '@angular/core';
import {ChartBase} from './chart-base';
import {GlucosemeterDaySummary} from '../../../models/response/data/response-glucosemeter-day-summary.model';
import {
	Category,
	Chart,
	DataLabel,
	DateTime,
	DateTimeCategory,
	IAccTooltipRenderEventArgs,
	IPointRenderEventArgs,
	Legend,
	LineSeries,
	ScatterSeries,
	StackingColumnSeries,
	StripLine,
	Tooltip,
	Zoom
} from '@syncfusion/ej2-angular-charts';
import {ResponseGlucosemeterData} from '../../../models/response/data/response-glucosemeter-data.model';
import {ResponseUserHealthGoal} from '../../../models/response/config/response-user-health-goal.model';
import {IAxisLabelRenderEventArgs, ITextRenderEventArgs} from '@syncfusion/ej2-charts/src/chart/model/chart-interface';

@Injectable({
	providedIn: 'root'
})
class GlucoseChartProperty {
	isVisible: boolean = false;
	meal_a_day: any[] = [];
	meal_total_day: any[] = [];
}

/**
 * 혈당 차트를 그린다
 */
export class ChartGlucoseProvider extends ChartBase {
	/**   O
	 * 생성자
	 */
	constructor() {
		super();
		Chart.Inject(DataLabel,LineSeries,StackingColumnSeries, ScatterSeries, Category, Tooltip, Legend , DateTime,DateTimeCategory, Zoom, StripLine);
	}


	private SERIES_PROPERTY = {
		AVERAGE: '평균선 그래프' ,
		BEFORE: '식전 혈당' ,
		AFTER: '식후 혈당' ,
		SLEEP: '취침전 혈당' ,
	};

	// 차트 데이터
	private data = {
		// 식전 차트 데이터
		before: new GlucoseChartProperty() ,

		// 식후 차트 데이터
		after: new GlucoseChartProperty() ,

		// 취침전 차트 데이터
		sleep: new GlucoseChartProperty()
	};

	/**
	 * 식전 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param rawData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawBeforeMeal(domIdentity: string, rawData: GlucosemeterDaySummary[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parseChartData(rawData);


		// 데이터 존재 여부
		const hasData: boolean = this.data.before.meal_a_day.length !== 0 || this.data.before.meal_total_day.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, this.getBackgroundSVG() ,options, hasData);


		// 차트 디테일 설정
		chartObject.subTitle = `평균: ${	this.getAverage(this.data.before.meal_a_day.map(i => i.y)).toFixed(0).toString().toSeparateComma()} mg/gL`;
		chartObject.series = [
			{
				name: this.SERIES_PROPERTY.BEFORE ,
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#FDA5A4',
				marker: {
					visible: true ,
					width: 6,
					height: 6,
					fill:'#FDA5A4'
				} ,
				dataSource: this.data.before.meal_total_day
			},
			{
				name: this.SERIES_PROPERTY.AVERAGE,
				type: 'Line',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#d54931',
				marker: {
					  visible: true
					, width: 5
					, height: 5
					, fill:'#d54931'
				},
				dataSource: this.data.before.meal_a_day
			},
		];
		chartObject.chartArea = {
			background: 'url(#gradient-chart)',
			border : {
				color: 'white',
				width:0
			},
		};
		chartObject.primaryXAxis = {
			majorGridLines : {
				width : 3 ,
				color : 'white'
			},
			lineStyle : {
				width : 0
			}
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			const yData = event.point.y;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일');
			textContent += `<br>`;

			// 시리즈 별로 처리
			switch (event.series.name) {
				case this.SERIES_PROPERTY.BEFORE :
					textContent += `${this.SERIES_PROPERTY.BEFORE}: <b>${yData.toFixed(0)}</b> mg/gL`;
					break;
				case this.SERIES_PROPERTY.AVERAGE :
					textContent += `${this.SERIES_PROPERTY.AVERAGE}: <b>${yData.toFixed(0)}</b> mg/gL`;
					break;
			}
			event.text = textContent;
		};
	}


	/**
	 * 식후 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param rawData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawAfterMeal(domIdentity: string, rawData: GlucosemeterDaySummary[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parseChartData(rawData);

		// 데이터 존재 여부
		const hasData: boolean = this.data.after.meal_a_day.length !== 0 || this.data.after.meal_total_day.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, this.getBackgroundSVG() ,options, hasData);

		// 차트 디테일 설정
		chartObject.subTitle = `평균: ${	this.getAverage(this.data.after.meal_a_day.map(i => i.y)).toFixed(0).toString().toSeparateComma()} mg/gL`;
		chartObject.series = [
			{
				name: this.SERIES_PROPERTY.AFTER ,
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#FDA5A4',
				marker: {
					visible: true ,
					width: 6,
					height: 6,
					fill:'#FDA5A4'
				} ,
				dataSource: this.data.after.meal_total_day
			},
			{
				name: this.SERIES_PROPERTY.AVERAGE,
				type: 'Line',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#d54931',
				marker: {
					visible: true ,
					width: 5 ,
					height: 5 ,
					fill:'#d54931' ,
				},
				dataSource: this.data.after.meal_a_day
			},
		];
		chartObject.chartArea = {
			background: 'url(#gradient-chart)',
			border : {
				color: 'white',
				width:0
			},
		};
		chartObject.primaryXAxis = {
			majorGridLines : {
				width : 3 ,
				color : 'white'
			},
			lineStyle : {
				width : 0
			}
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			const yData = event.point.y;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일');
			textContent += `<br>`;

			// 시리즈 별로 처리
			switch (event.series.name) {
				case this.SERIES_PROPERTY.AFTER :
					textContent += `${this.SERIES_PROPERTY.AFTER}: <b>${yData.toFixed(0)}</b> mg/gL`;
					break;
				case this.SERIES_PROPERTY.AVERAGE :
					textContent += `${this.SERIES_PROPERTY.AVERAGE}: <b>${yData.toFixed(0)}</b> mg/gL`;
					break;
			}
			event.text = textContent;
		};
	}

	/**
	 * 식전 차트를 그린다
	 * @param domIdentity dom Id 정보
	 * @param rawData 그래프 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawSleepMeal(domIdentity: string, rawData: GlucosemeterDaySummary[]
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터를 파싱한다
		this.parseChartData(rawData);

		// 데이터 존재 여부
		const hasData: boolean = this.data.sleep.meal_a_day.length !== 0 || this.data.sleep.meal_total_day.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChart(domIdentity, title, this.getBackgroundSVG() ,options, hasData);

		// 차트 디테일 설정
		chartObject.subTitle = `평균: ${	this.getAverage(this.data.sleep.meal_a_day.map(i => i.y)).toFixed(0).toString().toSeparateComma()} mg/gL`;
		chartObject.series = [
			{
				name: this.SERIES_PROPERTY.SLEEP ,
				type: 'Scatter',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#FDA5A4',
				marker: {
					visible: true ,
					width: 6,
					height: 6,
					fill:'#FDA5A4'
				} ,
				dataSource: this.data.sleep.meal_total_day
			},
			{
				name: this.SERIES_PROPERTY.AVERAGE,
				type: 'Line',
				xName: 'x' ,
				yName: 'y' ,
				fill: '#d54931',
				marker: {
					  visible: true
					, width: 5
					, height: 5
					, fill:'#d54931'
				},
				dataSource: this.data.sleep.meal_a_day
			},
		];
		chartObject.chartArea = {
			background: 'url(#gradient-chart)',
			border : {
				color: 'white',
				width:0
			},
		};
		chartObject.primaryXAxis = {
			majorGridLines : {
				width : 3 ,
				color : 'white'
			},
			lineStyle : {
				width : 0
			}
		};
		chartObject.tooltipRender =  (event: IAccTooltipRenderEventArgs) => {
			const xData = new Date((event.point.x as Date));
			const yData = event.point.y;

			let textContent: string = '';
			textContent += xData.format('yyyy년 MM월 dd일');
			textContent += `<br>`;


			// 시리즈 별로 처리
			switch (event.series.name) {
				case this.SERIES_PROPERTY.SLEEP :
					textContent += `${this.SERIES_PROPERTY.SLEEP}: <b>${yData.toFixed(0)}</b> mg/gL`;
					break;
				case this.SERIES_PROPERTY.AVERAGE :
					textContent += `${this.SERIES_PROPERTY.AVERAGE}: <b>${yData.toFixed(0)}</b> mg/gL`;
					break;
			}
			event.text = textContent;
		};
	}

	getAverage(data:any[]) {
		let total : number = 0;
		let count : number = 0;

		data.forEach(i => {
			if(!isNaN(i)) {
				total += i;
				count++;
			}
		});

		if(count > 0)
			return total / count;
		else
			return 0;
	}


	/**
	 * 스파이크 섬네일 그래프를 그린다
	 * @param domIdentity dom Id 정보
	 * @param rawData 그래프 데이터
	 * @param goalData 건강 목표 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawSpikeThumbnail(domIdentity: string, rawData: ResponseGlucosemeterData[]
		, goalData: ResponseUserHealthGoal
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,

	) {
		// 데이터 존재 여부
		const hasData: boolean = this.data.sleep.meal_a_day.length !== 0 || this.data.sleep.meal_total_day.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChartWithNoMessage(domIdentity, title, '',options, hasData);

		// 차트 디테일 설정
		chartObject.series = [
			{
				  type: 'Line'
				, fill: '#CECECE'
				, width: 2
				, xName: 'RegDate'
				, yName: 'Value'
				, dataSource: rawData
				, enableTooltip: true
				, legendShape: 'Circle'
				, marker : {
					  visible: true
					, width: 10
					, height: 10
					, fill:'#F8D358'
				}
			},
		];
		chartObject.tooltip.enable = false;
		chartObject.width = '140';
		chartObject.height = '100';
		chartObject.chartArea = {
			border : {
				width:1 ,
				color:'white'
			},
		};
		// Marker Draw 콜백 이벤트
		chartObject.pointRender = (args: IPointRenderEventArgs) => {
			const value = args.point.yValue;

			// Marker Base 색은 white 로 설정
			args.fill = 'white';
			args.border.width = 3;

			// 값에 따른 Marker 변경 처리
			switch(this.isHighLow(value, args.point.index, goalData)) {
				// 낮음
				case -1 :
					args.border.color = '#48A0DD';
					break;
				// 정상
				case 0 :
					args.border.color = '#A8E270';
					break;
				// 높음
				case 1 :
					args.border.color = '#FF6665';
					break;
			}
		};
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			event.text = '';
		};
		chartObject.primaryYAxis = {
			lineStyle : {
				width : 0
			},
			majorGridLines: {
				width : 0
			},
			minorTickLines: {
				width : 0,
			},
			majorTickLines: {
				width : 0
			},
			stripLines: [
				{
					start: 180  ,
					end: 400,
					zIndex: 'Behind',
					color: '#d3d3d3',
					opacity: 0.15,
					verticalAlignment: 'Start',
					horizontalAlignment: 'End',
				}    				,
				{
					start: 0,
					end: 100,
					zIndex: 'Behind',
					color: '#d3d3d3',
					opacity: 0.15,
					verticalAlignment: 'End',
					horizontalAlignment: 'End',
				}
			],
			edgeLabelPlacement:'None' ,
			plotOffsetBottom: 0,
		};
		chartObject.primaryXAxis = {
			visible: true,
			valueType: 'DateTimeCategory',
			majorGridLines: {
				width : 1,
				dashArray:'3'
			} ,
			majorTickLines: {
				width : 0
			},
			minorTickLines: {
				width : 0
			},
			border: {
				width:0
			},
			lineStyle: {
				width: 0
			},
			labelStyle : {
				size: '10'
			},
		};
	}

	/**
	 * 스파이크 그래프를 그린다
	 * @param domIdentity dom Id 정보
	 * @param rawData 그래프 데이터
	 * @param goalData 건강 목표 데이터
	 * @param title 그래프 타이틀
	 * @param options 옵션 daysAgo: ~일 이전 , startDate:(yyyy-MM-dd) 시작일 , endDate:(yyyy-MM-dd) 종료일
	 */
	drawSpike(domIdentity: string, rawData: ResponseGlucosemeterData[]
		, goalData: ResponseUserHealthGoal
		, title: string
		, options?: { daysAgo: number; startDate: string , endDate: string}  ,
	) {
		// 데이터 존재 여부
		const hasData: boolean = this.data.sleep.meal_a_day.length !== 0 || this.data.sleep.meal_total_day.length !== 0;

		// Base 차트를 그린다
		const chartObject = this.drawBaseChartWithNoMessage(domIdentity, title, '',options, hasData);

		// 차트 디테일 설정
		chartObject.series = [
			{
				  type: 'Line'
				, fill: '#CECECE'
				, width: 2
				, xName: 'RegDate'
				, yName: 'Value'
				, dataSource: rawData
				, enableTooltip: true
				, legendShape: 'Circle'
				, marker : {
					  visible: true
					, width: 10
					, height: 10
					, dataLabel: {
						position:'Middle',
						visible: true ,
						font : {
							fontFamily : this.FONT_FAMILY,
							fontWeight : 'Bold',
							size : '12' ,
						},
						opacity: 0.7,
					},
				}
			},
		];
		chartObject.height = '360';
		chartObject.chartArea = {
			border : {
				width:1 ,
				color:'white'
			},
		};
		chartObject.textRender = (args:ITextRenderEventArgs) => {
			args.template = '';
			// 값에 따른 Marker 변경 처리
			switch(this.isHighLow(args.point.yValue, args.point.index, goalData)) {
				// 낮음
				case -1 :
					args.template += `<div style="background-color: #48A0DD; border-radius: 10px;  padding: 5px; color: white;  font-weight: lighter;">${args.point.index === 0 ? '식전' : '식후'} ${args.point.y}</div>`;
					break;
				// 정상
				case 0 :
					args.template += `<div style="background-color: #A8E270; border-radius: 10px;  padding: 5px; color: white;  font-weight: lighter;">${args.point.index === 0 ? '식전' : '식후'} ${args.point.y}</div>`;
					break;
				// 높음
				case 1 :
					args.template += `<div style="background-color: #f76564; border-radius: 10px;  padding: 5px; color: white;  font-weight: lighter;">${args.point.index === 0 ? '식전' : '식후'} ${args.point.y}</div>`;
					break;
			}
		};
		// Marker Draw 콜백 이벤트
		chartObject.pointRender = (args: IPointRenderEventArgs) => {
			const value = args.point.yValue;

			// Marker Base 색은 white 로 설정
			args.fill = 'white';
			args.border.width = 3;



			// 값에 따른 Marker 변경 처리
			switch(this.isHighLow(value, args.point.index, goalData)) {
				// 낮음
				case -1 :
					args.border.color = '#48A0DD';
					break;
				// 정상
				case 0 :
					args.border.color = '#A8E270';
					break;
				// 높음
				case 1 :
					args.border.color = '#FF6665';
					break;
			}
		};
		chartObject.axisLabelRender = (event:IAxisLabelRenderEventArgs) => {
			event.text = '';
		};
		chartObject.primaryYAxis = {
			lineStyle : {
				width : 0
			},
			majorGridLines: {
				width : 0
			},
			minorTickLines: {
				width : 0,
			},
			majorTickLines: {
				width : 0
			},
			stripLines: [
				{
					start: 180  ,
					end: 400,
					zIndex: 'Behind',
					color: '#d3d3d3',
					opacity: 0.15,
					verticalAlignment: 'Start',
					horizontalAlignment: 'End',
				}    				,
				{
					start: 0,
					end: 100,
					zIndex: 'Behind',
					color: '#d3d3d3',
					opacity: 0.15,
					verticalAlignment: 'End',
					horizontalAlignment: 'End',
				}
			],
			edgeLabelPlacement:'None' ,
			plotOffsetBottom: 0,
		};
		chartObject.primaryXAxis = {
			visible: true,
			valueType: 'DateTimeCategory',
			majorGridLines: {
				width : 1,
				dashArray:'3'
			} ,
			majorTickLines: {
				width : 0
			},
			minorTickLines: {
				width : 0
			},
			border: {
				width:0
			},
			lineStyle: {
				width: 0
			},
			labelStyle : {
				size: '10'
			},
		};
		chartObject.tooltipRender = (tooltipData: IAccTooltipRenderEventArgs) => {
			// @ts-ignore
			const data = tooltipData.data;
			if(data.pointIndex === 0)
				tooltipData.text = `[<b>식전혈당</b>]<br/>${new Date(data.pointX).format('yyyy-MM-dd HH:mm')} <br/>혈당: <b>${data.pointY}</b> mg/gL`;
			else
				tooltipData.text = `[식후<b>${data.pointIndex}</b> 회차]<br/>${new Date(data.pointX).format('yyyy-MM-dd HH:mm')} <br/>혈당: <b>${data.pointY}</b> mg/gL`;
		};
	}


	// region 차트 데이터 파싱
	/**
	 * 차트 데이터를 파싱한다
	 * @param rawData 파싱전 로우 데이터
	 * @private
	 */
	private parseChartData(rawData: GlucosemeterDaySummary[]) {

		// 초기화
		this.data = {
			before: new GlucoseChartProperty() ,
			after: new GlucoseChartProperty() ,
			sleep: new GlucoseChartProperty()
		};

		// 데이터 처리
		(rawData as GlucosemeterDaySummary[]).forEach((gData: GlucosemeterDaySummary) => {
			// 식전 아침
			if (gData.MorningBeforeMeal)
				this.data.before.meal_total_day.push({x:gData.Date, y:gData.MorningBeforeMeal});
			// 식전 점심
			if (gData.AfternoonBeforeMeal)
				this.data.before.meal_total_day.push({x:gData.Date, y:gData.AfternoonBeforeMeal});
			// 식전 저녁
			if (gData.EveningBeforeMeal)
				this.data.before.meal_total_day.push({x:gData.Date, y:gData.EveningBeforeMeal});

			// 식후 아침
			if (gData.MorningAfterMeal)
				this.data.after.meal_total_day.push({x:gData.Date, y:gData.MorningAfterMeal});
			// 식후 점심
			if (gData.AfternoonAfterMeal)
				this.data.after.meal_total_day.push({x:gData.Date, y:gData.AfternoonAfterMeal});
			// 식후 저녁
			if (gData.EveningAfterMeal)
				this.data.after.meal_total_day.push({x:gData.Date, y:gData.EveningAfterMeal});

			// 취침전
			if (gData.BeforeSleep)
				this.data.sleep.meal_total_day.push({x:gData.Date, y:gData.BeforeSleep});

			// 하루동안의 식전 혈당
			const beforeMeal = this.getAverageGlucose(gData.Date, [ gData.MorningBeforeMeal, gData.AfternoonBeforeMeal, gData.EveningBeforeMeal ], 80, 120);
			if(!isNaN(beforeMeal.y))
				this.data.before.meal_a_day.push(beforeMeal);

			// 하루동안의 식후 혈당
			const afterMeal = this.getAverageGlucose(gData.Date, [ gData.MorningAfterMeal, gData.AfternoonAfterMeal, gData.EveningAfterMeal ], 100, 200);
			if(!isNaN(afterMeal.y))
				this.data.after.meal_a_day.push(afterMeal);

			// 취침전 식후 혈당
			const beforeSleepMeal = this.getAverageGlucose(gData.Date, [ gData.BeforeSleep ], 80, 120);
			if(!isNaN(beforeSleepMeal.y))
				this.data.sleep.meal_a_day.push(beforeSleepMeal);
		});

		// 데이터 있음 or 없음 여부
		this.data.before.isVisible 	= this.data.before.meal_a_day.length === 0 && this.data.before.meal_total_day.length === 0;
		this.data.after.isVisible	= this.data.after.meal_a_day.length === 0 && this.data.after.meal_total_day.length === 0;
		this.data.sleep.isVisible 	= this.data.sleep.meal_a_day.length === 0 && this.data.sleep.meal_total_day.length === 0;
	}

	/**
	 * 혈당평균 값을 가져온다
	 * @param time 시간
	 * @param glucoseList 혈당 리스트
	 * @param min 혈당 최소값
	 * @param max 혈당 최대값
	 * @private
	 */
	private getAverageGlucose(time: Date, glucoseList: number[], min?: number, max?: number) {
		let count = 0;
		let average = 0;
		let rtn: {  x: Date; y: number };
		rtn = {
			x: time,
			y: 0,
		};
		glucoseList.forEach(glucoseData => {
			if (glucoseData) {
				average += glucoseData;
				count++;
			}
		});
		rtn.y = parseFloat((average / count).toFixed(2));
		return rtn;
	}
	// endregion

	/**
	 * 백그라운드 이미지 메타를 리턴한다.
	 * @private
	 */
	getBackgroundSVG() {
		let meta : string = '';
		meta +=
			meta += '<svg style="height: 0">';
		meta += '<defs>';
		meta += '	<linearGradient id="gradient-chart" x1="0.5" x2="0.5" y2="0.998" gradientUnits="objectBoundingBox">';
		meta += '	<stop offset="0" stop-color="#fffcfa"/>';
		meta += '	<stop offset="1" stop-color="#fadbd7"/>';
		meta += '		</linearGradient>';
		meta += '		</defs>';
		meta += '		<rect id="_01" data-name="01" width="162" height="227" rx="4" opacity="0.555" fill="url(#gradient-chart)"/>';
		meta += '	<rect id="_01-2" data-name="01" width="162" height="227" rx="4" transform="translate(164.754)" opacity="0.555" fill="url(#gradient-chart)"/>';
		meta += '	<rect id="_01-3" data-name="01" width="162" height="227" rx="4" transform="translate(329.565)" opacity="0.555" fill="url(#gradient-chart)"/>';
		meta += '	<rect id="_01-4" data-name="01" width="162" height="227" rx="4" transform="translate(494.377)" opacity="0.555" fill="url(#gradient-chart)"/>';
		meta += '	<rect id="_01-5" data-name="01" width="162" height="227" rx="4" transform="translate(659.188)" opacity="0.555" fill="url(#gradient-chart)"/>';
		meta += '</svg>';

		return meta;
	}

	/**
	 * 주어진 측정 데이터가 목표 범위와 비교한 결과를 반환한다.
	 * if return <  0 : low     : blue
	 * if return == 0 : normal  : green
	 * if return >  0 : high    : red
	 * @param value 검사할 측정 데이터
	 * @param index 검사할 측정 데이터 순번
	 * @param goalDatas 목표 데이터
	 */
	isHighLow(value: number, index: number, goalDatas: ResponseUserHealthGoal): number {
		let minthr: number;
		let maxthr: number;

		// 식전
		if(index === 0) {
			minthr = goalDatas.GlucoseBeforeMealMin;
			maxthr = goalDatas.GlucoseBeforeMealMax;
		}
		// 식후
		else {
			minthr = goalDatas.GlucoseAfterMealMin;
			maxthr = goalDatas.GlucoseAfterMealMax;
		}

		if (value < minthr)
			return -1;
		else if (value > maxthr)
			return 1;
		else
			return 0;
	}
}
