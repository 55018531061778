import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {SessionCacheService} from './cache/cache.service';
import {environment} from '../../environments/environment';
import {RequestLogin} from '../models/request/account/request-login.model';
import {ResponseData} from '../models/response-data.model';
import {ResponseLogin} from '../models/response/account/response-login.model';
import {ResponseList} from '../models/response-list.model';
import {ResponseClaim} from '../models/response/account/response-claim.model';
import {EnumResponseResult} from '../models/enums/enum-response-result.model';

/**
 * 인증 서비스
 */
@Injectable({providedIn: 'root'})
export class AuthService {
	/**
	 * 로그인 여부 변경감지
	 */
	isLoginSource$: Observable<ResponseData>;
	/**
	 * 로그인 정보 소스 변경 감시
	 */
	loginSource$: Observable<ResponseLogin>;
	/**
	 * claim 캐시 이름
	 * @private
	 */
	private CACHE_CLAIM = 'claims';
	/**
	 * role 캐시 이름
	 * @private
	 */
	private CACHE_ROLE = 'roles';
	/**
	 * 인증 관련 API
	 * @private
	 */
	private AUTH_URI = `${environment.apiUrl}/account`;
	/**
	 * 로그인 여부 소스
	 * @private
	 */
	private _isLoginSource: BehaviorSubject<ResponseData>;
	/**
	 * 로그인 정보 소스
	 * @private
	 */
	private _loginSource: BehaviorSubject<ResponseLogin>;
	/**
	 * 요구되는 권한 목록
	 * @private
	 */
	private requiredRoles: string[] = ['User'];

	/**
	 * 생성자
	 * @param http HttpClient 객체
	 * @param sessionCacheService 세션 캐시 서비스 객체
	 */
	constructor(
		private http: HttpClient,
		private sessionCacheService: SessionCacheService,
	) {
		const initIsLogin: ResponseData = new ResponseData();
		initIsLogin.Result = EnumResponseResult.Warning;
		this._isLoginSource = new BehaviorSubject<ResponseData>(initIsLogin);
		this.isLoginSource$ = this._isLoginSource.asObservable();
		// @ts-ignore
		this._loginSource = new BehaviorSubject<ResponseLogin>(null);
		this.loginSource$ = this._loginSource.asObservable();
	}

	/**
	 * 로그인 처리
	 * @param userInfo 사용자 정보
	 * @param isForce 강제 로그인 여부
	 */
	login(userInfo: RequestLogin, isForce: boolean = false) {
		const URI: string = isForce ? 'loginforce' : 'login';
		return this.http.post<ResponseData<ResponseLogin>>(`${this.AUTH_URI}/${URI}`, userInfo)
			.pipe(
				map(result => {
					if (result.Result === EnumResponseResult.Success) {
						this.setLogin();

						// 유저 롤 정보 저장
						const roles = result.Data.Roles;

						// 기존 롤 삭제 후 새로 입력
						this.sessionCacheService.remove(this.CACHE_ROLE);

						if (roles) {
							this.sessionCacheService.set(this.CACHE_ROLE, roles);
						}


						const responseData = new ResponseData();
						responseData.Result = EnumResponseResult.Success;
						this._isLoginSource.next(responseData);
					}
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 토큰으로 로그인을 시도한다.
	 * @param token 로그인 토큰정보
	 */
	tryLoginToken( token : string) {
		return this.http.post<ResponseData<ResponseLogin>>(`${this.AUTH_URI}/LoginToken`,{ Token:token })
			.pipe(
				map(result => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 핸드폰 인증과 함께 외부 계정 로그인 처리
	 * @param phoneNumber 핸드폰 번호
	 * @param verifyCode 인증 코드
	 */
	externalLoginWithPhone(phoneNumber: string, verifyCode: string) {
		return this.http.post<ResponseData<ResponseLogin>>(`${this.AUTH_URI}/externallogin/${phoneNumber}/${verifyCode}`, null)
			.pipe(
				map(result => {
					if (result.Result === EnumResponseResult.Success) {
						this.setLogin();
						const responseData = new ResponseData();
						responseData.Result = EnumResponseResult.Success;
						this._isLoginSource.next(responseData);
					}
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 외부 계정 로그인 처리
	 * @param provider 핸드폰 번호
	 * @param accessToken 인증 코드
	 */
	externalLogin(provider: string, accessToken: string) {
		return this.http.post<ResponseData<ResponseLogin>>(`${this.AUTH_URI}/externallogin`, { provider, accessToken })
			.pipe(
				map(result => {
					if (result.Result === EnumResponseResult.Success) {
						this.setLogin();
						const responseData = new ResponseData();
						responseData.Result = EnumResponseResult.Success;
						this._isLoginSource.next(responseData);
					}
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그아웃 처리
	 */
	logout() {
		return this.http.get<ResponseData>(`${this.AUTH_URI}/logout`)
			.pipe(
				map((result) => {
					this.setLogout();
					const responseData = new ResponseData();
					responseData.Result = EnumResponseResult.Error;
					this._isLoginSource.next(responseData);
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인 여부 확인
	 */
	checkLogin(): Observable<ResponseData> {
		return this.http.get<ResponseData>(`${this.AUTH_URI}/CheckLogin/${this.requiredRoles.join(',')}`)
			.pipe(
				map((result) => {
					this._isLoginSource.next(result);
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인 정보 가져오기
	 */
	getLogin(): Observable<ResponseData<ResponseLogin>> {
		return this.http.get<ResponseData<ResponseLogin>>(`${this.AUTH_URI}/login`)
			.pipe(
				map((result) => {

					this._loginSource.next(result.Data);

					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인한 사용자의 권한 가져오기
	 */
	getClaims(): Observable<ResponseList<ResponseClaim>> {
		return this.http.get<ResponseList<ResponseClaim>>(`${this.AUTH_URI}/claims`);
	}

	/**
	 * 로그인한 사용자의 Rol 가져오기
	 */
	getCacheRoles(): [] {
		// 캐시 값이 있는 경우
		if (this.sessionCacheService.has(this.CACHE_ROLE)) {
			return this.sessionCacheService.get(this.CACHE_ROLE);
		}

		return null;
	}

	/**
	 * 권한 존재 여부 확인
	 * @param claimValue 검사할 권한 값
	 */
	hasClaim(claimValue: string) {
		return this.http.get<ResponseData>(`${this.AUTH_URI}/claims/${claimValue}`)
			.pipe(
				map(() => {
					return true;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 권한 존재 여부 확인(캐시에서 확인)
	 * @param claimValue 검사할 권한 값
	 */
	async hasClaimInChache(claimValue: string) {

		// 캐시 값이 있는 경우
		if (this.sessionCacheService.has(this.CACHE_CLAIM)) {
			return this.sessionCacheService.hasValue(this.CACHE_CLAIM, claimValue);
		}
		// 없는 경우 캐시에 저장
		else {
			const claims = await this.getClaims().toPromise();
			if (claims) {
				this.sessionCacheService.set(this.CACHE_CLAIM, claims.Data.Items.map(i => i.ClaimValue));
			}

			return this.sessionCacheService.hasValue(this.CACHE_CLAIM, claimValue);
		}
	}

	/**
	 * 권한 존재 여부 확인(캐시에서 확인)
	 * @param claimValues 검사할 권한 값 목록
	 */
	async hasClaimInChacheOneOf(claimValues: string[]) {
		let result: boolean = false;

		// 모든 권한에 대해서 처리
		for (const claimValue of claimValues) {

			// 권한이 존재하는 경우
			if (await this.hasClaimInChache(claimValue)) {
				result = true;
				break;
			}
		}

		return result;
	}

	/**
	 * 현재 로그인한 유저의 claim 을 가져온다
	 */
	getCurrentUserClaims() : any {
		return this.sessionCacheService.get(this.CACHE_CLAIM);
	}

	/**
	 * 로그인 여부
	 */
	isLogined(): any {
		return this.getCurrentUserClaims() !== null;
	}


	/**
	 * 권한 존재 여부 확인(캐시에서 확인)
	 * @param claimValues 검사할 권한 값 목록
	 */
	async getHasClaimsInChache(claimValues: string[]) {

		const result: { [id: string]: boolean; } = {};

		// 모든 권한에 대해서 처리
		for (const claimValue of claimValues) {

			// 권한이 존재하는 경우
			if (await this.hasClaimInChache(claimValue)) {
				result[claimValue] = true;
			}
		}

		return result;
	}

	/**
	 * 현재 로그인한 사용자의 마지막 활동 시간 정보를 수정한다.
	 */
	updateLastActivityTime(): Observable<ResponseData> {
		return this.http.put<ResponseData>(`${this.AUTH_URI}/LastActivityTime`, null)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 로그인 후 처리
	 * @private
	 */
	async setLogin() {
		// 기존 권한 삭제 후 새로 입력
		this.sessionCacheService.remove(this.CACHE_CLAIM);

		const claims = await this.getClaims().toPromise();

		if (claims) {
			this.sessionCacheService.set(this.CACHE_CLAIM, claims.Data.Items.map(i => i.ClaimValue));
		}
	}

	/**
	 * 로그아웃 후 처리
	 * @private
	 */
	private setLogout() {
		// 권한 세션 캐시 삭제
		this.sessionCacheService.remove(this.CACHE_CLAIM);
	}
}
