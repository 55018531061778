import {AfterViewInit, Component, ElementRef, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-tabs-content',
  templateUrl: './tabs-content.component.html',
  styleUrls: ['./tabs-content.component.scss']
})
export class TabsContentComponent implements AfterViewInit {
	/**
	 * 부모로 전달할 Input 객체
	 */
	@ViewChild('content') content: ElementRef;
	/**
	 * 부모로 전달할 Input 객체
	 */
	@Output() contentElement: ElementRef;


	constructor() { }

	/**
	 *  ngAfterViewInit
	 */
	ngAfterViewInit() {
		this.contentElement = this.content;
	}
}
