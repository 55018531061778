import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ResponseData} from '../../models/response-data.model';
import {catchError, map} from 'rxjs/operators';
import {SessionCacheService} from '../cache/cache.service';
import {RequestCommunityCreate} from '../../models/request/community/request-community.model';
import {RequestCommunityInfo} from '../../models/request/community/request-community-info.model';
import {
	RequestCommunityTitleAndDescription
} from '../../models/request/community/request-community-title-and-description.model';
import {RequestCommunityBanner} from '../../models/request/community/request-community-banner.model';
import {RequestCommunityLogo} from '../../models/request/community/request-community-logo.model';
import {ResponseList} from '../../models/response-list.model';
import {ResponseUserShareHealthCareData} from '../../models/response/account/response-user-share-health-care-data';
import {ResponseCommunity} from '../../models/response/community/response-community.model';
import {RequestCommunityPost} from '../../models/request/community/request-community-post.mode';
import {RequestCommunityPostComment} from '../../models/request/community/request-community-post-comment.model';
import {ResponseCommunityPostComment} from '../../models/response/community/response-community-post-comment.model';
import {ResponseCommunityPost} from '../../models/response/community/response-community-post.model';
import {RequestCommunityMenu} from '../../models/request/community/request-community-menu.model';

@Injectable({
	providedIn: 'root'
})
export class CommunityProvider {
	// 커뮤니티 정보 URI
	private readonly API_URI: string = `${environment.apiUrl}/Communities`;

	/**
	 * 생성자
	 * @param httpClient httpClient 객체
	 * @param sessionCacheService sessionCacheService 객체
	 */
	constructor(private httpClient: HttpClient,
				private sessionCacheService: SessionCacheService) {
	}

	/**
	 * 커뮤니티를 개설한다.
	 * @param request 커뮤니티 개설 요청 객체
	 */
	createAsync(request: RequestCommunityCreate): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URI}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 정보를 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param request 타이틀 및 설명 수정 요청 정보
	 */
	updateAsync(communityId: string, request: RequestCommunityInfo): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}`,  request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 메뉴를 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param menuId 메뉴 아이디
	 * @param request 타이틀 및 설명 수정 요청 정보
	 */
	UpdateMenuAsync(communityId: string, menuId: string, request: RequestCommunityMenu): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/${menuId}`,  request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 메뉴를 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param request 타이틀 및 설명 수정 요청 정보
	 */
	AddMenuAsync(communityId: string, request: RequestCommunityMenu): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URI}/${communityId}/Menus`,  request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 메뉴를 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param request 타이틀 및 설명 수정 요청 정보
	 */
	RemoveMenuAsync(communityId: string, menuId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URI}/${communityId}/${menuId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 타이틀 및 설명을 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param request 커뮤니티 타이틀 및 설명 수정 요청 정
	 */
	updateAsyncTitleAndDescription(communityId: string, request: RequestCommunityTitleAndDescription): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/TitleAndDescription`,  request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 타이틀 및 설명을 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param request 커뮤니티 배너 수정 요청 정보
	 */
	updateAsyncBanner(communityId: string, request: RequestCommunityBanner): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/Banner`,   request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 타이틀 및 설명을 수정한다.
	 * @param communityId 커뮤니티 아이디
	 * @param request 커뮤니티 배너 수정 요청 정보
	 */
	updateAsyncLogo(communityId: string, request: RequestCommunityLogo): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/Logo`,   request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티를 패쇄를 요청한다.
	 * @param communityId 패쇄하려는 커뮤니티 아이디
	 */
	requestShutDownAsync(communityId: string): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/ShutDown`,  {})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티를 패쇄한다.
	 * @param communityId 패쇄하려는 커뮤니티 아이디
	 * @param verifyCode 패쇄 확인 코드
	 */
	shutDownAsync(communityId: string, verifyCode: number): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/ShutDown/${verifyCode}`,  {})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티를 패쇄한다.
	 * @param communityId 패쇄하려는 커뮤니티 아이디
	 * @param verifyCode 패쇄 확인 코드
	 */
	ShutDownForceAsync(communityId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URI}/${communityId}/ShutDownForce`,  {})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티명이 중복되지 않았는지를 검사한다.
	 * @param communityId 커뮤니티 아이디 (신규 개설 시, null)
	 * @param param RequestCommunityCheckName 커뮤니티명 중복 검사 요청 객체
	 */
	isUniqueNameAsync( communityId: string ,param:any): Observable<ResponseData<boolean>> {
		return this.httpClient.post<ResponseData<boolean>>(`${this.API_URI}/${communityId}/IsUniqueName`, param)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 URI가 중복되지 않았는지를 검사한다.
	 * @param communityId 커뮤니티 아이디 (신규 개설 시, null)
	 * @param param RequestCommunityCheckUri 커뮤니티 URI 중복 검사 요청 객체
	 */
	isUniqueUriAsync( communityId: string ,param:any): Observable<ResponseList<ResponseUserShareHealthCareData>> {
		return this.httpClient.post<ResponseList<ResponseUserShareHealthCareData>>(`${this.API_URI}/${communityId}/IsUniqueUri`, param)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 목록를 가져온다.
	 * @param param 검색 파라미터
	 */
	get(param: any = null): Observable<ResponseList<ResponseCommunity>> {
		return this.httpClient.get<ResponseList<ResponseCommunity>>(`${this.API_URI}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 목록를 가져온다.
	 * @param param 검색 파라미터
	 */
	getMaster(param: any = null): Observable<ResponseList<ResponseCommunity>> {
		return this.httpClient.get<ResponseList<ResponseCommunity>>(`${this.API_URI}/Master`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 슈퍼바이져 커뮤니티 목록를 가져온다.
	 * @param param 검색 파라미터
	 */
	getSupervisorCommunity(param: any = null): Observable<ResponseList<ResponseCommunity>> {
		return this.httpClient.get<ResponseList<ResponseCommunity>>(`${this.API_URI}/Supervisor`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 정보를 가져온다.
	 * @param communityId 커뮤니티 아이디
	 */
	getAsync(communityId: string): Observable<ResponseData<ResponseCommunity>> {
		return this.httpClient.get<ResponseData<ResponseCommunity>>(`${this.API_URI}/${communityId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 정보를 가져온다.
	 * @param uri 커뮤니티 Uri
	 */
	getAsyncWithUri(uri: string): Observable<ResponseData<ResponseCommunity>> {
		return this.httpClient.get<ResponseData<ResponseCommunity>>(`${this.API_URI}/WithUri/${uri}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	// region  커뮤니티 포스트 관련
	/**
	 *
	 * @param communityId 커뮤니티 아이디
	 * @param menuId 메뉴 아이디
	 * @param request 커뮤니티 글 등록 요청 정보
	 */
	registPostAsync(communityId: string,menuId:string,request:RequestCommunityPost): Observable<ResponseData> {
		return this.httpClient.post<ResponseData>(`${this.API_URI}/${communityId}/${menuId}/posts`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티를 패쇄한다.
	 * @param communityId 패쇄하려는 커뮤니티 아이디
	 * @param menuId 메뉴 아이디
	 * @param postId 포스트 아이디
	 * @param request 커뮤니티 글 수정 요청 정보
	 */
	updatePostAsync(communityId: string, menuId: string , postId: string,request:RequestCommunityPost): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/${menuId}/Posts/${postId}`,  request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 글을 삭제한다.
	 * @param communityId 커뮤니티 아이디
	 * @param menuId 메뉴 아이디
	 * @param postId 커뮤니티 글 아이디
	 */
	deletePostAsync(communityId: string, menuId: string , postId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URI}/${communityId}/${menuId}/Posts/${postId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 전체 글 목록를 가져온다.
	 * @param communityId 커뮤니티 아이디
	 * @param param 검색 파라미터
	 */
	getPostsAsync(communityId: string,param:any): Observable<ResponseList<ResponseCommunity>> {
		return this.httpClient.get<ResponseList<ResponseCommunity>>(`${this.API_URI}/${communityId}/Posts`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 글 목록를 가져온다.
	 * @param communityId 커뮤니티 아이디
	 * @param menuId 메뉴 아이디
	 * @param param 검색 파라미터
	 */
	getPostsAsyncAllPost(communityId: string,menuId: string,param:any): Observable<ResponseList<ResponseCommunityPost>> {
		return this.httpClient.get<ResponseList<ResponseCommunityPost>>(`${this.API_URI}/${communityId}/${menuId}/Posts`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 글 상세 정보를 가져온다.
	 * @param communityId 커뮤니티 아이디
	 * @param menuId 메뉴 아이디
	 * @param postId 커뮤니티 글 아이디
	 */
	getPostsAsyncDetail(communityId: string,menuId:string,postId:string): Observable<ResponseList<ResponseCommunity>> {
		return this.httpClient.get<ResponseList<ResponseCommunity>>(`${this.API_URI}/${communityId}/${menuId}/Posts/${postId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 글 상세 정보를 가져온다.
	 * @param postId 커뮤니티 글 아이디
	 */
	getPostInfoAsync(postId:string): Observable<ResponseData<ResponseCommunityPost>> {
		return this.httpClient.get<ResponseData<ResponseCommunityPost>>(`${this.API_URI}/${postId}/SharePostView`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
	// endregion

	// region  커뮤니티 포스트 덧글 관련
	/**
	 *
	 * @param postId 포스트 아이디
	 * @param request 커뮤니티 글 등록 요청 정보
	 */
	registPostCommentAsync(postId: string, request: RequestCommunityPostComment ): Observable<ResponseData> {
		return this.httpClient.post<ResponseData<ResponseCommunityPostComment>>(`${this.API_URI}/PostComments/${postId}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 하위 덧글을 등록한다.
	 * @param postId 포스트 아이디
	 * @param parentCommentId 상위 덧글 아이디
	 * @param request 커뮤니티 글 등록 요청 정보
	 */
	registPostCommentAsyncChild(postId: string, parentCommentId:string, request: RequestCommunityPostComment ): Observable<ResponseData<ResponseCommunityPostComment>> {
		return this.httpClient.post<ResponseData<ResponseCommunityPostComment>>(`${this.API_URI}/PostComments/${postId}/${parentCommentId}`, request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 덧글을 수정한다.
	 * @param postId 포스트 아이디
	 * @param commentId 커뮤니티 덧글 아이디
	 * @param request 커뮤니티 글 덧글 수정 요청 정보
	 */
	updatePostCommentAsync( postId: string,commentId: string,request:RequestCommunityPostComment): Observable<ResponseData> {
		return this.httpClient.put<ResponseData<ResponseCommunityPostComment>>(`${this.API_URI}/PostComments/${postId}/${commentId}`,  request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}

	/**
	 * 커뮤니티 글을 삭제한다.
	 * @param postId 커뮤니티 글 아이디
	 * @param commentId 커뮤니티 덧글 아이디
	 */
	deletePostCommentAsync(postId: string,commentId: string): Observable<ResponseData> {
		return this.httpClient.delete<ResponseData>(`${this.API_URI}/PostComments/${postId}/${commentId}`)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 덧글 목록를 가져온다.
	 * @param postId 커뮤니티 글 아이디
	 * @param param 검색 옵션
	 */
	getPostCommentsAsync(postId: string,param:any): Observable<ResponseList<ResponseCommunityPostComment>> {
		return this.httpClient.get<ResponseList<ResponseCommunityPostComment>>(`${this.API_URI}/PostComments/${postId}`, { params: param })
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 커뮤니티 덧글 목록를 가져온다.
	 * @param postId 커뮤니티 글 아이디
	 * @param commentId 커멘트 아이디
	 * @param param 페이징 파라미터
	 */
	getPostCommentAsyncDetail(postId: string,commentId: string, param:any): Observable<ResponseList<ResponseCommunityPostComment>> {
		return this.httpClient.get<ResponseList<ResponseCommunityPostComment>>(`${this.API_URI}/PostComments/${postId}/${commentId}`,{ params: param})
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}


	/**
	 * 메뉴의 순번을 재정렬한다.
	 * @param communityId  커뮤니티 아이디
	 * @param request 요청 정보
	 */
	updateMenuOrderAsync(communityId:string, request:any): Observable<ResponseData> {
		return this.httpClient.put<ResponseData>(`${this.API_URI}/${communityId}/menu-orders`,request)
			.pipe(
				map((result) => {
					return result;
				}),
				catchError((err) => {
					return throwError(err);
				})
			);
	}
	// endregion
}

