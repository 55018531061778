// Libraries
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';

import {DynamicPipe} from '../pipes/dynamic.pipe';

// 번역 json 파일 로드
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	imports: [
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
	],
	declarations: [
		DynamicPipe,
	],
	exports: [
		DynamicPipe,
	],
})
export class CommonDynamicPipeModule {

}
